import React, { FC, useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Box, Flex, Text } from 'theme-ui'
import { tooltipUnregister } from '../../../actions/tooltipActions'
import { PADDING } from '../../../constants/containerConstants'
import {
  BREAKPOINT_L_VALUE,
  BREAKPOINT_M_VALUE,
} from '../../../constants/themeConstants'
import { isBrowser } from '../../../helpers/dom'
import useHoverIdle from '../../../hooks/useHoverIdle'
import useOutsideClick from '../../../hooks/useOutsideClick'
import useWindowSize from '../../../hooks/useWindowSize'
import { TooltipState } from '../../../reducers/tooltipReducer'
import defaultTheme from '../../../themes/defaultTheme'
import IconWrapper from '../../atoms/IconWrapper/IconWrapper'
import { TooltipProps } from './Tooltip'
import TooltipAction from './TooltipAction'
import TooltipTingle from './TooltipTingle'

const getContainerSpacing = (windowWidth: number, customEdgeGap?: number) => {
  if (customEdgeGap) {
    return defaultTheme.space[customEdgeGap] || customEdgeGap
  }

  if (windowWidth < BREAKPOINT_M_VALUE) return defaultTheme.space[PADDING.MOBILE]

  if (windowWidth < BREAKPOINT_L_VALUE) return defaultTheme.space[PADDING.TABLET]

  return defaultTheme.space[PADDING.DESKTOP]
}

const calculateShiftX = (
  balloon?: HTMLDivElement,
  customEdgeGap?: number,
  fixedEdgeGap?: number
) => {
  if (!balloon || !isBrowser) return 0

  const documentWidth = document.body.clientWidth
  const containerPadding = getContainerSpacing(documentWidth, customEdgeGap)
  const { left, right } = balloon.getBoundingClientRect()
  const minLeftPosition = containerPadding
  const maxRightPosition = documentWidth - containerPadding

  if (left <= minLeftPosition) {
    return Math.abs(fixedEdgeGap || Math.round(minLeftPosition - left))
  }

  if (right >= maxRightPosition) {
    return -Math.abs(fixedEdgeGap || Math.round(right - maxRightPosition))
  }

  return 0
}

const TooltipBalloon: FC<TooltipProps & TooltipState> = ({
  name,
  icon,
  label,
  actionLabel,
  actionCallback,
  actionLink,
  edgeGap,
  dataTId,
  fixedEdgeGap,
}) => {
  const ref = useRef<HTMLDivElement>(null)
  const dispatch = useDispatch()
  const { width: windowWidth } = useWindowSize(100)
  const [shiftX, setShiftX] = useState(0)

  const unregisterTooltip = useCallback(
    () => dispatch(tooltipUnregister(name)),
    [dispatch, name]
  )

  const idleReset = useHoverIdle(ref, () => unregisterTooltip())

  const onActionClick = useCallback(() => {
    if (actionCallback) actionCallback()

    unregisterTooltip()
  }, [actionCallback, unregisterTooltip])

  useOutsideClick(ref, () => {
    unregisterTooltip()
    idleReset()
  })

  useEffect(() => {
    if (ref.current && !fixedEdgeGap) {
      setShiftX(calculateShiftX(ref.current, edgeGap))
    } else if (ref.current && fixedEdgeGap) {
      setShiftX(calculateShiftX(ref.current, undefined, fixedEdgeGap))
    }
  }, [windowWidth, edgeGap, fixedEdgeGap])

  return (
    <Box sx={{ zIndex: 9999999992, position: 'relative' }}>
      <TooltipTingle />

      <Box
        ref={ref}
        sx={{
          position: 'absolute',
          left: '50%',
          transform: 'translateX(-50%)',
          width: '280px',
        }}
      >
        <Text
          variant="smallText"
          sx={{
            display: 'block',
            transform: `translateX(${shiftX}px)`,
            marginTop: 3,
            backgroundColor: 'text',
            padding: 3,
            borderRadius: 'default',
            width: '100%',
            color: 'textLight',
          }}
        >
          <Flex
            sx={{
              svg: {
                marginTop: '-8px',
              },
            }}
          >
            <div>
              <IconWrapper icon={icon} size={5} color="currentColor" />
            </div>

            <Box
              data-t-id={dataTId !== '' ? dataTId : undefined}
              sx={{
                marginLeft: icon ? 2 : undefined,
              }}
            >
              {label}

              <TooltipAction actionCallback={onActionClick} actionLink={actionLink}>
                {actionLabel}
              </TooltipAction>
            </Box>
          </Flex>
        </Text>
      </Box>
    </Box>
  )
}

export default TooltipBalloon
