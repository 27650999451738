import React, { FC, useCallback, useContext, useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import { Box, Text } from 'theme-ui'
import { ICONS } from '../../../constants/iconConstants'
import { CATEGORY_FILTER_ID } from '../../../constants/searchConstants'
import { URL_PAGE_PARAM } from '../../../constants/urlConstants'
import { getFlattenedCategoryTree } from '../../../helpers/categoryHelper'
import { getFilterStringByActiveSearchResultFilterObject } from '../../../helpers/filterHelper'
import { getTextFieldValue } from '../../../helpers/layoutServiceHelper'
import { CategoriesContext } from '../../../providers/CategoriesProvider'
import { PartSearchResultContext } from '../../../providers/PartSearchResultContextProvider'
import { SearchContext } from '../../../providers/SearchContextProvider'
import { TextField } from '../../../types/layoutService'
import ClickableWrapper from '../../atoms/ClickableWrapper/ClickableWrapper'
import { getDividerSx } from '../../atoms/Divider/Divider'
import IconWrapper from '../../atoms/IconWrapper/IconWrapper'

interface RemoveCategoryFilterButtonProps {
  label: TextField
  callback?: () => void
}

const RemoveCategoryFilterButton: FC<RemoveCategoryFilterButtonProps> = ({
  label,
  callback,
}) => {
  const history = useHistory()
  const { category } = useContext(SearchContext)
  const { categories } = useContext(CategoriesContext)
  const { activeFilters } = useContext(PartSearchResultContext)

  const categoryFilterId = useMemo(
    () => activeFilters?.[CATEGORY_FILTER_ID]?.[0],
    [activeFilters]
  )

  const subcategory = useMemo(
    () =>
      categoryFilterId
        ? getFlattenedCategoryTree(categoryFilterId, categories)?.[1]
        : undefined,
    [categoryFilterId, categories]
  )

  const removeCategoryFilter = useCallback(() => {
    const categoryFilter = category?.id
    const newFilterObject = {
      ...activeFilters,
      ...(categoryFilter
        ? { [CATEGORY_FILTER_ID]: [categoryFilter] }
        : { [CATEGORY_FILTER_ID]: [] }),
    }
    const params = new URLSearchParams(history.location.search)

    params.delete(URL_PAGE_PARAM)

    history.push(
      `?${getFilterStringByActiveSearchResultFilterObject(
        newFilterObject,
        params,
        false
      )}`
    )

    if (callback) callback()
  }, [activeFilters, callback, category, history])

  return (
    <Text
      variant="heading4"
      sx={{
        display: 'block',
        paddingBottom: [3, 3, 3, 4],
        marginBottom: [3, 3, 3, 4],
        ...getDividerSx('bottom'),
      }}
    >
      <Box
        as="span"
        sx={{
          display: ['none', 'none', 'none', 'inline-block'],
          mr: 2,
        }}
      >
        {getTextFieldValue(label)}
      </Box>

      <ClickableWrapper
        onClick={removeCategoryFilter}
        sx={{
          fontWeight: 'bold',
          color: 'primary',
          width: ['100%', '100%', '100%', 'auto'],
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <span>{subcategory?.description || ''}</span>
        <IconWrapper icon={ICONS.CROSS} size={4} />
      </ClickableWrapper>
    </Text>
  )
}

export default RemoveCategoryFilterButton
