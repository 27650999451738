import React, { createContext, useContext, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import axios from 'axios'
import { useQuery } from '@tanstack/react-query'
import useAxiosClient from '../hooks/services/rest/core/useAxiosClient'
import { AxiosClientType } from '../providers/AxiosClientProvider'
import { UserContext } from '../providers/UserProvider'
import { ActiveStoreProviderContext } from '../providers/ActiveStoreProvider'
import fetchDafCompanyVehicles, {
  DafCompanyVehiclesData,
  Vehicle,
} from '../services/rest/ecommerce/dafCompanyVehicles'
import { DafDefaultQueryParams } from '../types/userProps'
import filterAndGroupVehiclesForMaintenancePlan from '../helpers/vehicleHelper'
import fetchDmscVehicleOptions, {
  VehicleOption,
} from '../services/rest/dmsc/dmscVehicleOptions'
import useSitecoreContext from '../hooks/useSitecoreContext'
import {
  DMSC_OPTION_DOUBLE,
  DMSC_OPTION_LIST,
  INSPECTION_INTERVAL_UK,
  MAINTENANCE_PACKAGE_UK,
} from '../constants/dmscConstants'
import {
  URL_REQUEST_MAINTENANCE_PLAN_MAINTENANCE_HISTORY,
  URL_REQUEST_MAINTENANCE_PLAN_OVERVIEW,
  URL_REQUEST_MAINTENANCE_PLAN_USAGE_INFORMATION,
  URL_STEP,
  URL_VEHCILE_TYPE,
} from '../constants/urlConstants'
import { scrollTo } from '../helpers/dom'
import { getQueryParam } from '../helpers/urlHelper'

interface RequestMaintenancePlanProviderProps {
  children: React.ReactNode
}

interface RequestMaintenancePlanContextType {
  activeStep: number
  isFetching: boolean
  dafCompanyVehiclesData: DafCompanyVehiclesData | undefined
  groupedVehicles: Record<string, Vehicle[]> | undefined
  isFetchingVehicleOptions: boolean
  selectedVehicleType: string | undefined
  vehicleOptions: VehicleOption[] | undefined
}

const RequestMaintenancePlanContext =
  createContext<RequestMaintenancePlanContextType>({
    activeStep: 1,
    isFetching: false,
    dafCompanyVehiclesData: undefined,
    groupedVehicles: undefined,
    isFetchingVehicleOptions: false,
    selectedVehicleType: undefined,
    vehicleOptions: undefined,
  })

export const RequestMaintenancePlanProvider = ({
  children,
}: RequestMaintenancePlanProviderProps) => {
  const [vehicleType, setVehicleType] = useState<string | null>(null)
  const [activeStep, setActiveStep] = useState(1)
  const location = useLocation()
  const {
    languageContext: { cultureCode },
  } = useSitecoreContext()
  const client = useAxiosClient(AxiosClientType.CommerceApi)
  const { user } = useContext(UserContext)
  const { actingCompanyId, actingSupplierId, isImpersonated } = useContext(
    ActiveStoreProviderContext
  )

  useEffect(() => {
    const step = getQueryParam(URL_STEP)
    const type = getQueryParam(URL_VEHCILE_TYPE)

    if (step === URL_REQUEST_MAINTENANCE_PLAN_OVERVIEW) {
      setActiveStep(1)
    } else if (step === URL_REQUEST_MAINTENANCE_PLAN_USAGE_INFORMATION) {
      setActiveStep(2)
    } else if (step === URL_REQUEST_MAINTENANCE_PLAN_MAINTENANCE_HISTORY) {
      setActiveStep(3)
    }

    if (type) {
      setVehicleType(type)
    }

    scrollTo({ top: 0, behavior: 'auto' })
  }, [location.search])

  const dafVehicleDetailQueryParams: DafDefaultQueryParams = {
    customerCompanyId: actingCompanyId,
    supplierCompanyId: actingSupplierId,
    isImpersonated,
  }

  const getDafCompanyVehicles = async () => {
    const { token } = axios.CancelToken.source()
    const response = await fetchDafCompanyVehicles(
      client,
      dafVehicleDetailQueryParams,
      token,
      user
    )
    return response.data
  }

  const { isFetching, data: dafCompanyVehiclesData } = useQuery({
    queryKey: ['dafCompanyVehicles'],
    queryFn: getDafCompanyVehicles,
    enabled: !!actingCompanyId && !!actingSupplierId,
  })

  const groupedVehicles = React.useMemo(() => {
    if (!dafCompanyVehiclesData) return {}
    return filterAndGroupVehiclesForMaintenancePlan(
      dafCompanyVehiclesData?.result?.vehicles
    )
  }, [dafCompanyVehiclesData])

  const firstVehicleOfGroup = groupedVehicles?.[vehicleType ?? '']?.[0]

  const getDMSCVehicleOptions = async () => {
    const { token } = axios.CancelToken.source()
    const response = await fetchDmscVehicleOptions(
      dafVehicleDetailQueryParams,
      client,
      token,
      firstVehicleOfGroup.chassisNumber,
      cultureCode ?? '',
      user
    )
    return response.data
  }

  const { isLoading: isFetchingVehicleOptions, data: dmscVehicleOptionsData } =
    useQuery({
      queryKey: ['dmscVehicleOptionsData', vehicleType],
      queryFn: () => getDMSCVehicleOptions(),
      enabled: !!actingCompanyId && !!actingSupplierId && !!vehicleType,
    })

  const vehicleOptions = dmscVehicleOptionsData?.result?.vehicleOptions
    ?.filter(
      (vehicleOption) =>
        vehicleOption.option.isAmendable &&
        vehicleOption.option.description !== MAINTENANCE_PACKAGE_UK &&
        vehicleOption.option.description !== INSPECTION_INTERVAL_UK &&
        vehicleOption.option.name !== MAINTENANCE_PACKAGE_UK &&
        vehicleOption.option.name !== INSPECTION_INTERVAL_UK &&
        ((vehicleOption.option.optionType === DMSC_OPTION_LIST &&
          vehicleOption.possibleValues.length > 1) ||
          vehicleOption.option.optionType === DMSC_OPTION_DOUBLE)
    )
    .sort((a, b) => a.option.sequenceNumber - b.option.sequenceNumber)

  return (
    <RequestMaintenancePlanContext.Provider
      value={{
        activeStep,
        isFetching,
        dafCompanyVehiclesData,
        groupedVehicles,
        isFetchingVehicleOptions,
        vehicleOptions,
        selectedVehicleType: vehicleType ?? undefined,
      }}
    >
      {children}
    </RequestMaintenancePlanContext.Provider>
  )
}

export const useRequestMaintenancePlan = () => {
  return useContext(RequestMaintenancePlanContext)
}
