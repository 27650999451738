import React, { useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Box, Flex, Text } from 'theme-ui'
import FormAccordion from '../FormAccordion/FormAccordion'
import Spinner from '../../../atoms/Spinner/Spinner'
import { Vehicle } from '../../../../services/rest/ecommerce/dafCompanyVehicles'
import { useRequestMaintenancePlan } from '../../../../providers/RequestMaintenancePlanProvider'
import UsageInformationForm, {
  UsageInformationFormRef,
} from './UsageInformationForm'
import NoResults from '../../../molecules/NoResults/NoResults'
import Button from '../../../atoms/Button/Button'
import { ICONS } from '../../../../constants/iconConstants'
import { RequestMaintenancePlanWizardDatasourceProps } from '../RequestMaintenancePlanWizard'
import { getTextFieldValue } from '../../../../helpers/layoutServiceHelper'
import { COLORS } from '../../../../constants/themeConstants'
import i18next from 'i18next'
import {
  URL_REQUEST_MAINTENANCE_PLAN_OVERVIEW,
  URL_STEP,
  URL_VEHCILE_TYPE,
} from '../../../../constants/urlConstants'

interface UsageInformationOverviewProps {
  vehicles: Record<string, Vehicle[]> | undefined
  datasource: RequestMaintenancePlanWizardDatasourceProps
}

const UsageInformationOverview = ({
  vehicles,
  datasource,
}: UsageInformationOverviewProps) => {
  const [formErrors, setFormErrors] = useState<
    { vin: string; hasErrors: boolean }[]
  >([])
  const { vehicleOptions, isFetchingVehicleOptions, selectedVehicleType } =
    useRequestMaintenancePlan()
  const history = useHistory()

  // Create refs for each form
  const formRefs = useRef<Record<string, React.RefObject<UsageInformationFormRef>>>(
    {}
  )

  // Initialize refs for forms if they don't exist
  if (vehicles && selectedVehicleType && vehicles[selectedVehicleType]) {
    vehicles[selectedVehicleType].forEach((vehicle) => {
      if (!formRefs.current[vehicle.vin]) {
        formRefs.current[vehicle.vin] = React.createRef<UsageInformationFormRef>()
      }
    })
  }

  const handleFormErrorsChange = (hasErrors: boolean, vin: string) => {
    setFormErrors((prevErrors) => {
      const existingErrorIndex = prevErrors.findIndex((error) => error.vin === vin)
      if (existingErrorIndex !== -1) {
        const updatedErrors = [...prevErrors]
        updatedErrors[existingErrorIndex] = { vin, hasErrors }
        return updatedErrors
      } else {
        return [...prevErrors, { vin, hasErrors }]
      }
    })
  }

  const isFormValidByVin = (vin: string) => {
    const error = formErrors.find((error) => error.vin === vin)
    return error ? !error.hasErrors : true
  }

  const changeStepHandler = () => {
    const params = new URLSearchParams(location.search)
    if (params.has(URL_VEHCILE_TYPE)) {
      params.delete(URL_VEHCILE_TYPE)
    }
    params.set(URL_STEP, URL_REQUEST_MAINTENANCE_PLAN_OVERVIEW)
    history.replace(`${location.pathname}?${params.toString()}`)
  }

  const handleClickCopyValues = () => {
    if (
      !vehicles ||
      !selectedVehicleType ||
      !vehicles[selectedVehicleType] ||
      vehicles[selectedVehicleType].length <= 1
    ) {
      return
    }

    // Get references to the first vehicle form and all other vehicle forms
    const firstVehicleVin = vehicles[selectedVehicleType][0].vin
    const firstVehicleFormRef = formRefs.current[firstVehicleVin]

    if (!firstVehicleFormRef || !firstVehicleFormRef.current) {
      return
    }

    const firstVehicleFormApi = firstVehicleFormRef.current.getFormApi()
    if (!firstVehicleFormApi) {
      return
    }

    // Get the current values from the first form
    const firstVehicleValues = firstVehicleFormApi.getState().values

    // We need to get field names including fields that might have been emptied
    // Let's collect field names from all forms to ensure we catch all possible fields
    const allFieldNames = new Set<string>()

    // Add fields from the first vehicle's form
    if (vehicleOptions) {
      // Add the estimated annual distance field
      allFieldNames.add('estimatedAnnualDistance')

      // Add fields based on vehicle options
      vehicleOptions.forEach((option) => {
        if (!option.hide) {
          allFieldNames.add(`s-${option.option.optionId}`)
        }
      })
    }

    // Apply values to all other vehicles
    vehicles[selectedVehicleType].slice(1).forEach((vehicle) => {
      const formRef = formRefs.current[vehicle.vin]
      if (formRef && formRef.current) {
        const formApi = formRef.current.getFormApi()
        if (formApi) {
          // Use batch to reduce renders
          formApi.batch(() => {
            // Copy each field, whether it has a value or not
            allFieldNames.forEach((fieldName) => {
              // Get the value from the first form, which might be undefined
              const value = firstVehicleValues[fieldName]

              // Set the value in the target form
              // If the value is undefined, this will effectively clear the field
              formApi.change(fieldName, value)
            })
          })
        }
      }
    })
  }

  return (
    <Box>
      {vehicles &&
        selectedVehicleType &&
        vehicles[selectedVehicleType] &&
        vehicles[selectedVehicleType].length > 0 && (
          <>
            <Box>
              <Text
                variant="heading2"
                color={COLORS.BLACK}
                sx={{ fontSize: [4, 4, 4, 4] }}
              >
                {i18next.t(getTextFieldValue(datasource.stepTwoTitle), {
                  vehicleType: selectedVehicleType,
                })}
              </Text>
            </Box>
            <Box>
              <Text variant="bodyCompact" color={COLORS.GRAY}>
                {getTextFieldValue(datasource.stepTwoSubtitle)}
              </Text>
            </Box>
            <Box
              as="ul"
              role="list"
              sx={{
                listStyle: 'none',
                padding: 0,
                display: 'flex',
                flexDirection: 'column',
                gap: 4,
                marginBlockStart: 6,
              }}
            >
              {vehicles[selectedVehicleType].map((vehicle, index) => {
                return (
                  <li key={vehicle.vin}>
                    <FormAccordion
                      vinLabel={getTextFieldValue(datasource?.vin)}
                      accordionOpen={
                        vehicle.vin === vehicles[selectedVehicleType][0].vin
                      }
                      vehicle={vehicle}
                      isValid={
                        formErrors.length > 0 && isFormValidByVin(vehicle?.vin)
                      }
                    >
                      <Box sx={{ padding: 3 }}>
                        {isFetchingVehicleOptions ? (
                          <Spinner />
                        ) : (
                          <Box
                            sx={{
                              flexGrow: 1,
                              overflow: 'auto',
                            }}
                          >
                            {vehicle && (
                              <>
                                {!vehicle || isFetchingVehicleOptions ? (
                                  <Box sx={{ marginBlockStart: 4 }}>
                                    <Spinner size={4} />
                                  </Box>
                                ) : null}

                                {!isFetchingVehicleOptions && vehicleOptions && (
                                  <>
                                    <UsageInformationForm
                                      key={vehicle.vin}
                                      vin={vehicle.vin}
                                      onFormErrorsChange={handleFormErrorsChange}
                                      ref={formRefs.current[vehicle.vin]}
                                    />

                                    {index === 0 &&
                                      vehicles[selectedVehicleType].length > 1 && (
                                        <Flex
                                          sx={{
                                            justifyContent: 'end',
                                          }}
                                        >
                                          <Button
                                            onClick={handleClickCopyValues}
                                            icon={ICONS.COPY}
                                          >
                                            {getTextFieldValue(
                                              datasource.copyToAllVehiclesButton
                                            )}
                                          </Button>
                                        </Flex>
                                      )}
                                  </>
                                )}
                              </>
                            )}
                          </Box>
                        )}
                      </Box>
                    </FormAccordion>
                  </li>
                )
              })}
            </Box>
            <Box
              sx={{
                flexDirection: ['column', 'column', 'row'],
                display: 'flex',
                justifyContent: 'flex-end',
                marginBlockStart: 4,
                gap: 4,
              }}
            >
              <Box>
                <Button
                  onClick={() => changeStepHandler()}
                  variant="outline"
                  icon={ICONS.ARROW_LEFT}
                  iconPosition="start"
                  sx={{ width: ['100%', '100%', null] }}
                >
                  {getTextFieldValue(datasource.previousButton)}
                </Button>
              </Box>
              <Box>
                <Button
                  variant="primary"
                  icon={ICONS.ARROW_RIGHT}
                  iconPosition="end"
                  sx={{ width: ['100%', '100%', null] }}
                  disabled={
                    !formErrors.length || formErrors.some((item) => item.hasErrors)
                  }
                >
                  {getTextFieldValue(datasource.enterMaintenanceHistoryButton)}
                </Button>
              </Box>
            </Box>
          </>
        )}

      {!vehicles ||
        (!vehicleOptions && !isFetchingVehicleOptions && (
          <Box sx={{ marginBlock: 5 }}>
            <NoResults
              description={getTextFieldValue(datasource.noResultsDescription)}
              title={getTextFieldValue(datasource.noResultsTitle)}
            >
              <Button
                as="a"
                href={datasource.backToMyFleetButton.url}
                variant="primary"
                icon={ICONS.ARROW_LEFT}
                iconPosition="start"
              >
                {datasource.backToMyFleetButton.text}
              </Button>
            </NoResults>
          </Box>
        ))}
    </Box>
  )
}

export default UsageInformationOverview
