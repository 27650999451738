import dayjs from 'dayjs'
import i18next from 'i18next'
import {
  NonMultiSupportServices,
  VehicleDetail,
  VehicleDetailResponse,
} from '../services/rest/ecommerce/dafVehicleDetail'
import { CommerceResponse } from '../types/commerceApi'
import { UpcomingJob, Vehicle } from '../services/rest/ecommerce/dafCompanyVehicles'
import { VEHICLE_SERVICES_NON_MULTISUPPORT } from '../constants/dafCompanyVehicleConstants'

export const validateAndGetDate = (date: string | undefined | null) =>
  dayjs(date).isValid() && date ? dayjs(date).format('DD-MM-YYYY') : '-'

export const showDashWhenEmptyValue = (value: string | number | undefined | null) =>
  value || '-'

export const getVehicleConnectedValue = (
  vehicleDetailResponse: CommerceResponse<VehicleDetailResponse>
) => {
  if (vehicleDetailResponse?.result?.hasDafConnect !== true)
    return i18next.t('myFleet.no')

  return `${i18next.t('myFleet.yes')} (${validateAndGetDate(
    vehicleDetailResponse?.result?.mileage?.date
  )})`
}

export const getMileageValue = (
  mileage: number | undefined | null,
  cultureCode: string | undefined
) => {
  if (mileage === null) return null
  if (mileage === undefined) return undefined
  if (cultureCode === undefined) return undefined
  const formattedMileage = new Intl.NumberFormat(cultureCode).format(mileage)
  return `${formattedMileage} ${i18next.t('myFleet.kilometersAbbr')}`
}

export const getEngineType = (
  vehicleDetailResponse: CommerceResponse<VehicleDetailResponse>
) => {
  if (
    vehicleDetailResponse?.result?.vehicleDetail?.vehicleAttributes?.enginePower !==
      undefined &&
    vehicleDetailResponse?.result?.vehicleDetail?.vehicleAttributes?.engineType ===
      undefined
  ) {
    return vehicleDetailResponse?.result?.vehicleDetail?.vehicleAttributes
      ?.enginePower
  }
  if (
    vehicleDetailResponse?.result?.vehicleDetail?.vehicleAttributes?.enginePower ===
      undefined &&
    vehicleDetailResponse?.result?.vehicleDetail?.vehicleAttributes?.engineType !==
      undefined
  ) {
    return vehicleDetailResponse?.result?.vehicleDetail?.vehicleAttributes
      ?.engineType
  }
  if (
    vehicleDetailResponse?.result?.vehicleDetail?.vehicleAttributes?.enginePower !==
      undefined &&
    vehicleDetailResponse?.result?.vehicleDetail?.vehicleAttributes?.engineType !==
      undefined
  ) {
    return `${vehicleDetailResponse?.result?.vehicleDetail?.vehicleAttributes?.enginePower} ${vehicleDetailResponse?.result?.vehicleDetail?.vehicleAttributes?.engineType}`
  }
  return null
}

export const getVehicleLabel = (vehicle: VehicleDetail | undefined) => {
  if (vehicle === undefined) {
    return '...'
  }

  if (vehicle.customerVehicleId && vehicle.vehicleAttributes.registrationNumber) {
    return `${vehicle.customerVehicleId} – ${vehicle.vehicleAttributes.registrationNumber}`
  }

  if (vehicle.customerVehicleId && !vehicle.vehicleAttributes.registrationNumber) {
    return vehicle.customerVehicleId
  }

  if (!vehicle.customerVehicleId && vehicle.vehicleAttributes.registrationNumber) {
    return vehicle.vehicleAttributes.registrationNumber
  }

  return vehicle.vehicleAttributes.vin
}

export const getVehicleLabelForOverview = (vehicle: Vehicle) => {
  if (vehicle.customerVehicleId && vehicle.registrationNumber) {
    return `${vehicle.customerVehicleId} – ${vehicle.registrationNumber}`
  }

  if (vehicle.customerVehicleId && !vehicle.registrationNumber) {
    return vehicle.customerVehicleId
  }

  if (!vehicle.customerVehicleId && vehicle.registrationNumber) {
    return vehicle.registrationNumber
  }

  return vehicle.vin
}

export const vehicleHasMaintenancePlan = (vehicle: Vehicle | undefined): boolean => {
  return (
    vehicle?.nonMultiSupportServices?.some(
      (service: NonMultiSupportServices) =>
        service.type === VEHICLE_SERVICES_NON_MULTISUPPORT
    ) ?? false
  )
}

export const vehicleDetailHasMaintenancePlan = (
  vehicleDetail: VehicleDetailResponse | undefined
): boolean => {
  return (
    vehicleDetail?.vehicleDetail?.nonMultiSupportServices?.some(
      (service: NonMultiSupportServices) =>
        service.type === VEHICLE_SERVICES_NON_MULTISUPPORT
    ) ?? false
  )
}

export const orderPartsFormMaintenanceLink = (
  cultureCode: string | undefined,
  chassisNumber: string | undefined,
  upcomingJobs: UpcomingJob[]
) => {
  const jobCodes: string[] = []
  upcomingJobs
    .filter((job) => !job.markedAsComplete)
    .forEach((job) => {
      jobCodes.push(...job.rmiJobCode)
    })
  return `/${cultureCode}/embed?source=/${cultureCode}/Parts/${encodeURIComponent(
    `SelectJobByCode?chassisNumber=${chassisNumber}&jobCode=${jobCodes.join(
      '&jobCode='
    )}`
  )}`
}

const filterAndGroupVehiclesForMaintenancePlan = (vehicles: Vehicle[]) => {
  const filteredVehicles = vehicles.filter(
    (vehicle) =>
      !vehicle.rmContract &&
      vehicle.hasDafConnect &&
      !vehicleHasMaintenancePlan(vehicle)
  )

  const groupedVehicles = filteredVehicles.reduce((grouped, vehicle) => {
    const { vehicleType } = vehicle
    if (!grouped[vehicleType]) {
      grouped[vehicleType] = []
    }
    grouped[vehicleType].push(vehicle)
    return grouped
  }, {} as Record<string, Vehicle[]>)

  return Object.entries(groupedVehicles)
    .sort(([typeA, vehiclesA], [typeB, vehiclesB]) => {
      if (vehiclesB.length === vehiclesA.length) {
        return typeA.localeCompare(typeB)
      }
      return vehiclesB.length - vehiclesA.length
    })
    .reduce((sortedGrouped, [type, vehicles]) => {
      sortedGrouped[type] = vehicles
      return sortedGrouped
    }, {} as Record<string, Vehicle[]>)
}

export default filterAndGroupVehiclesForMaintenancePlan
