import React from 'react'
import i18next from 'i18next'
import { Box } from 'theme-ui'
import { getFormattedPriceString } from '../../../helpers/priceHelper'
import ShoppingBasketLabelValueItem from './ShoppingBasketLabelValueItem'
import useSitecoreContext from '../../../hooks/useSitecoreContext'
import { CommerceOrderSummary } from '../../../types/commerceApi'
import { loyaltyPoints } from '../../../helpers/commerceApiHelpers'

interface ShoppingBasketDetailsProps {
  orderSummary?: CommerceOrderSummary
}

const ShoppingBasketDetails = ({ orderSummary }: ShoppingBasketDetailsProps) => {
  const {
    languageContext: { cultureCode },
  } = useSitecoreContext()
  if (!orderSummary) return null

  return (
    <>
      <Box
        variant={orderSummary?.totalNumberOfItems ? '' : 'visuallyHidden'}
        sx={{
          marginBlockEnd: 4,
        }}
      >
        <ShoppingBasketLabelValueItem
          label={i18next.t('shoppingLabels.webshopNr')}
          value={orderSummary?.orderNumber}
          dataTId="BasketSidepanel-WebshopNo"
        />

        <ShoppingBasketLabelValueItem
          label={i18next.t('shoppingLabels.createdBy')}
          value={orderSummary?.createdByUserName}
          dataTId="BasketSidepanel-CreatedBy"
          suppressValue
        />

        <ShoppingBasketLabelValueItem
          label={i18next.t('shoppingLabels.basketProducts')}
          value={orderSummary?.totalNumberOfItems}
          dataTId="BasketSidepanel-AmountProducts"
          suppressValue
        />
        <ShoppingBasketLabelValueItem
          label={i18next.t('commonLabels.total')}
          value={getFormattedPriceString(
            cultureCode,
            orderSummary?.totalPrice?.currencyCode,
            orderSummary?.totalPrice?.value
          )}
          dataTId="BasketSidepanel-TotalCost"
          primary
        />
        {orderSummary?.customerLoyalty &&
          !!orderSummary.customerLoyalty.loyaltyPoints && (
            <ShoppingBasketLabelValueItem
              label={i18next.t('shoppingLabels.customerLoyalty.loyaltyPoints')}
              value={loyaltyPoints(
                orderSummary?.customerLoyalty.loyaltyPoints,
                cultureCode
              )}
            />
          )}
      </Box>
    </>
  )
}

export default ShoppingBasketDetails
