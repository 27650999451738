import React, { FC } from 'react'
import { Box, Text } from 'theme-ui'
import HotjarSuppress from '../../atoms/HotjarSuppress/HotjarSuppress'

interface ShoppingBasketLabelValueItemProps {
  label: string
  value?: string | number
  suppressValue?: boolean
  primary?: boolean
  dataTId?: string
}

const ShoppingBasketLabelValueItem: FC<ShoppingBasketLabelValueItemProps> = ({
  label,
  value,
  suppressValue = false,
  primary = false,
  dataTId,
}) => {
  if (!value) return null

  return (
    <Text
      variant="bodySmall"
      sx={{
        display: 'flex',
        lineHeight: ['heading2', 'heading2', 'heading2', 'menuList'],
        width: '100%',
        fontSize: [2, 2, 1],
        color: 'dark',
        whiteSpace: 'nowrap',
        fontWeight: primary ? 'bold' : null,
      }}
    >
      {label}

      <HotjarSuppress condition={suppressValue}>
        <Box
          data-t-id={dataTId}
          sx={{
            color: 'gray.2',
            textAlign: 'right',
            whiteSpace: 'normal',
            overflowWrap: 'break-word',
            ml: 2,
            flexGrow: 1,
            ...(primary
              ? {
                  fontWeight: 'boldNumeric',
                  color: 'primary',
                }
              : {}),
          }}
        >
          {value}
        </Box>
      </HotjarSuppress>
    </Text>
  )
}

export default ShoppingBasketLabelValueItem
