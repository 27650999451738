import React, { FC, useState } from 'react'
import { Box, Flex, Text } from 'theme-ui'
import { useTheme } from '@emotion/react'
import IconWrapper from '../../atoms/IconWrapper/IconWrapper'
import ClickableWrapper from '../../atoms/ClickableWrapper/ClickableWrapper'
import {
  TASK_VARIANT_OK,
  TASK_VARIANT_URGENT,
  TASK_VARIANT_URGENT_LARGE,
  TASK_VARIANT_WARNING,
} from '../../../constants/dafCompanyVehicleConstants'
import { ICONS } from '../../../constants/iconConstants'

export type UrgentTaskVariants =
  | typeof TASK_VARIANT_URGENT
  | typeof TASK_VARIANT_OK
  | typeof TASK_VARIANT_URGENT_LARGE
  | typeof TASK_VARIANT_WARNING

interface MultisupportUrgentTaskProps {
  variant: UrgentTaskVariants
  value?: string | null
  subValue?: string | null
  href?: string
  amount?: number
  onClickText?: string | null
  onClick?: () => void
  dataTId?: string
}

const MultisupportUrgentTask: FC<MultisupportUrgentTaskProps> = ({
  variant,
  value,
  subValue,
  href,
  amount,
  onClickText,
  onClick,
  dataTId,
}) => {
  const { urgentTasks, urgentTaskIcons } = useTheme()
  const getTaskVariant = () => {
    switch (variant) {
      case TASK_VARIANT_URGENT:
        return urgentTasks.urgent
      case TASK_VARIANT_URGENT_LARGE:
        return urgentTasks.urgentLarge
      case TASK_VARIANT_WARNING:
        return urgentTasks.warning
      default:
        return urgentTasks.ok
    }
  }
  const getTaskIconVariant = () => {
    switch (variant) {
      case TASK_VARIANT_URGENT:
        return urgentTaskIcons.urgent
      case TASK_VARIANT_URGENT_LARGE:
      case TASK_VARIANT_WARNING:
        return urgentTaskIcons.urgentLarge
      default:
        return urgentTaskIcons.ok
    }
  }
  const [hover, setHover] = useState(false)
  const urgentTaskVariant = getTaskVariant()
  const urgentTask = (
    <Flex
      onMouseOver={() => setHover(true)}
      onMouseOut={() => setHover(false)}
      data-t-id={dataTId}
      sx={{
        ...urgentTaskVariant,
        color: 'gray.3',
        width: '100%',
        flexDirection: 'row',
        alignItems: 'center',
        borderLeft: urgentTaskVariant.borderLeft,
        borderColor:
          href !== undefined && hover
            ? urgentTaskVariant.hoverColor
            : urgentTaskVariant.borderColor,
        ':first-of-type': {
          borderTopLeftRadius: urgentTaskVariant.borderRadius,
          borderTopRightRadius: urgentTaskVariant.borderRadius,
        },
        ':last-of-type': {
          borderBottomLeftRadius: urgentTaskVariant.borderRadius,
          borderBottomRightRadius: urgentTaskVariant.borderRadius,
        },
      }}
    >
      {(variant === TASK_VARIANT_OK ||
        variant === TASK_VARIANT_URGENT_LARGE ||
        variant === TASK_VARIANT_WARNING) && (
        <Box sx={{ marginRight: 3 }}>
          <IconWrapper
            icon={getTaskIconVariant()}
            size={5}
            color={urgentTaskVariant.borderColor}
          />
        </Box>
      )}
      {amount !== undefined && (
        <Box>
          <Text
            sx={{
              fontWeight: 'bold',
              fontSize: 3,
              marginLeft: 1,
              marginRight: 3,
            }}
          >
            {amount}
          </Text>
        </Box>
      )}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          width: '100%',
          alignItems: ['flex-start', 'flex-start', 'center'],
        }}
      >
        <Box sx={{ flexGrow: 1, textAlign: 'start' }}>
          <Text
            sx={{
              color: 'gray.3',
            }}
            variant="heading4"
          >
            {value}
          </Text>
          {subValue && (
            <Text variant="bodySmall" color="grey">
              {subValue}
            </Text>
          )}
        </Box>
        <Box>
          {onClick && onClickText && (
            <ClickableWrapper
              data-t-id={`warningbutton-${onClickText}`}
              as="a"
              onClick={onClick}
              sx={{
                color: urgentTaskVariant.borderColor,
                fontSize: 1,
                fontWeight: 'bold',
                marginRight: [1, 1, 3],
              }}
            >
              <Flex
                sx={{
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <span>{onClickText}</span>
                <IconWrapper icon={ICONS.ARROW_RIGHT} size={4} />
              </Flex>
            </ClickableWrapper>
          )}
        </Box>
      </Box>
    </Flex>
  )
  return (
    <>
      {href !== undefined && (
        <ClickableWrapper
          data-t-id={dataTId}
          as="a"
          sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'stretch',
            textAlign: 'start',
          }}
          href={href}
          onClick={onClick}
        >
          {urgentTask}
        </ClickableWrapper>
      )}
      {href === undefined && <>{urgentTask}</>}
    </>
  )
}

export default MultisupportUrgentTask
