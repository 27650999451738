import React, { FC, FormEvent, useCallback, useMemo, useState } from 'react'
import { Box, BoxProps, Flex, Text } from 'theme-ui'
import { Checkbox, Label } from 'theme-ui'
import { useCookies } from 'react-cookie'
import {
  CONSENT_EXPIRATION_IN_YEARS,
  CONSENT_TYPE_ANALYTICAL,
  CONSENT_TYPE_FUNCTIONAL,
  CONSENT_TYPE_FUNCTIONAL_SOCIAL,
  CONSENT_TYPE_MARKETING,
  USER_COOKIE_CONSENT,
} from '../../../constants/cookieConstants'
import Button from '../../atoms/Button/Button'
import Container from '../../atoms/Container/Container'
import Divider from '../../atoms/Divider/Divider'
import RichText from '../../atoms/RichText/RichText'
import { DEFAULT_COOKIE_CONSENT_OPTIONS } from '../../../constants/cookieConsentConstants'
import { updateConsentDataLayer } from '../../../helpers/dataLayerHelper'
import { TextField } from '../../../types/layoutService'
import { ConsentCookieFormState } from '../../../types/cookieTypes'
import { getTextFieldValue } from '../../../helpers/layoutServiceHelper'
import { getCurrentDate } from '../../../helpers/dateHelper'

export interface CookieConsentBaseProps
  extends Pick<BoxProps, Exclude<keyof BoxProps, 'css'>> {
  cookieHeader: TextField
  description: TextField
  acceptAll: TextField
  saveSettings: TextField
  changeSettings: TextField
  functionalCookies: TextField
  allowAnalyticsCookies: TextField
  allowSocialCookies: TextField
  allowMarketingCookies: TextField
}

export interface CookieConsentOption {
  disabled: boolean
  text: string
  value: string
}

const CookieConsentBase: FC<CookieConsentBaseProps> = ({
  cookieHeader,
  description,
  acceptAll,
  saveSettings,
  changeSettings,
  functionalCookies,
  allowAnalyticsCookies,
  allowSocialCookies,
  allowMarketingCookies,
  sx,
  ...containerProps
}) => {
  const [optionsVisible, setOptionsVisible] = useState<boolean>(false)

  const toggleOptionsVisibility = useCallback(() => {
    setOptionsVisible(!optionsVisible)
  }, [optionsVisible])

  const cookieOptions = useMemo<CookieConsentOption[]>(
    () => [
      {
        disabled: DEFAULT_COOKIE_CONSENT_OPTIONS.functional,
        text: getTextFieldValue(functionalCookies),
        value: CONSENT_TYPE_FUNCTIONAL,
      },
      {
        disabled: DEFAULT_COOKIE_CONSENT_OPTIONS.analytical,
        text: getTextFieldValue(allowAnalyticsCookies),
        value: CONSENT_TYPE_ANALYTICAL,
      },
      {
        disabled: DEFAULT_COOKIE_CONSENT_OPTIONS.functionalsocial,
        text: getTextFieldValue(allowSocialCookies),
        value: CONSENT_TYPE_FUNCTIONAL_SOCIAL,
      },
      {
        disabled: DEFAULT_COOKIE_CONSENT_OPTIONS.marketing,
        text: getTextFieldValue(allowMarketingCookies),
        value: CONSENT_TYPE_MARKETING,
      },
    ],
    [
      allowAnalyticsCookies,
      allowMarketingCookies,
      allowSocialCookies,
      functionalCookies,
    ]
  )

  const [cookie, setCookie] = useCookies([USER_COOKIE_CONSENT])

  const [formState, setFormState] = useState<ConsentCookieFormState>(
    cookie?.[USER_COOKIE_CONSENT] || DEFAULT_COOKIE_CONSENT_OPTIONS
  )

  const updateCookieConsent = useCallback(
    (currentFormState: ConsentCookieFormState) =>
      setCookie(USER_COOKIE_CONSENT, JSON.stringify(currentFormState), {
        expires: getCurrentDate(CONSENT_EXPIRATION_IN_YEARS),
        path: '/',
        encode: (s) => s,
      }),
    [setCookie]
  )

  const updateConsentChannels = useCallback(async () => {
    setOptionsVisible(false)

    updateConsentDataLayer(formState)
    updateCookieConsent(formState)
  }, [formState, updateCookieConsent])

  const handleFormSubmit = useCallback(
    async (event: FormEvent) => {
      event.preventDefault()
      await updateConsentChannels()
    },
    [updateConsentChannels]
  )

  const handleAcceptAll = useCallback(async () => {
    Object.keys(formState).forEach((key) => {
      formState[key] = true
    })

    await updateConsentChannels()
  }, [formState, updateConsentChannels])

  const updateFormState = (name: string, value: boolean) => {
    setFormState({ ...formState, [name]: value })
  }

  return (
    <Container sx={sx} {...containerProps}>
      <Box
        mb={[4, 4, 5]}
        sx={{
          width: ['100%', '100%', '50%'],
        }}
      >
        <Text as="h2" variant="heading2" pb={3}>
          {cookieHeader?.value}
        </Text>

        <Text as="p" color="gray.2" pb={3}>
          <RichText textField={description} />
        </Text>

        <Flex
          sx={{
            alignItems: 'center',
          }}
        >
          <Button variant="primary" onClick={() => handleAcceptAll()}>
            {getTextFieldValue(acceptAll)}
          </Button>

          <Button mx="2" variant="outline" onClick={() => toggleOptionsVisibility()}>
            {getTextFieldValue(changeSettings)}
          </Button>
        </Flex>
      </Box>

      <Box
        as="form"
        onSubmit={(e) => handleFormSubmit(e)}
        sx={{
          display: optionsVisible ? 'block' : 'none',
        }}
      >
        <Divider mb={[4, 4, 5]} />

        <Flex
          sx={{
            flexDirection: 'column',
            textAlign: 'left',
            width: ['100%', '100%', '25%'],
          }}
        >
          {cookieOptions.map((option) => (
            <Label
              key={option.value}
              htmlFor={option.value}
              sx={{
                display: 'flex',
                flex: '1',
                py: 1,
                mb: 2,
                justifyContent: ['start', 'start', 'space-between'],
                flexDirection: ['row-reverse', 'row-reverse', 'row'],
              }}
            >
              <Text as="p" color="gray.2">
                {option.text}
              </Text>

              <Checkbox
                id={option.value}
                checked={formState[option.value]}
                onChange={(e) => {
                  updateFormState(option.value, e.currentTarget.checked)
                }}
                disabled={option.disabled}
              />
            </Label>
          ))}
        </Flex>

        <Button type="submit" variant="primary" mt="3">
          {getTextFieldValue(saveSettings)}
        </Button>
      </Box>
    </Container>
  )
}

export default CookieConsentBase
