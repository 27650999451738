import i18next from 'i18next'
import React, {
  ClipboardEvent,
  FC,
  FocusEvent,
  KeyboardEvent,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  createOrderMutation,
  markOrderMutationFinished,
  setOrderRemarks,
} from '../../../../../actions/shoppingBasket/wizard'
import useCommerceApiPostService from '../../../../../hooks/services/rest/ecommerce/useCommerceApiPostService'
import useCommerceApiResponse from '../../../../../hooks/services/rest/ecommerce/useCommerceApiResponse'
import { ActiveUserStateContext } from '../../../../../providers/ActiveUserStateProvider'
import { RootState } from '../../../../../reducers'
import {
  OrderCustomerOrderRemarks,
  OrderCustomerOrderRemarksRequestProps,
  postOrderCustomerOrderRemarks,
} from '../../../../../services/rest/ecommerce/order/order-customer-order-remarks'
import { CommerceResponse } from '../../../../../types/commerceApi'
import FormControl from '../../../../atoms/FormControls/FormControl'
import FormFieldText from '../../../../atoms/FormControls/FormFieldText'
import FormInputLabel from '../../../../atoms/FormControls/FormInputLabel'
import FormInputTextareaAsync from '../../../../atoms/FormControls/FormInputTextareaAsync'
import { QUOTE } from '../../../../../constants/orderConstants'

const SubmittableOrderField: FC = () => {
  const dispatch = useDispatch()

  const { orderNumber } = useContext(ActiveUserStateContext)
  const { order } = useSelector((state: RootState) => state.shoppingBasketWizard)
  const [error, setError] = useState<string | null>(null)
  const inputRef = useRef<HTMLInputElement>(null)

  const defaultValue = useMemo(() => order?.remarks?.value || '', [order])

  const [posting, response, post] = useCommerceApiPostService<
    OrderCustomerOrderRemarksRequestProps,
    CommerceResponse<OrderCustomerOrderRemarks>
  >(postOrderCustomerOrderRemarks)

  useCommerceApiResponse<OrderCustomerOrderRemarks>({
    response,
    onResult: (e: OrderCustomerOrderRemarks | null) => {
      const value = e || ''

      dispatch(setOrderRemarks(value))
      dispatch(markOrderMutationFinished())

      if (inputRef.current) inputRef.current.value = value
    },
    onMessage: (message) => {
      if (message.severity === 'Error' && !!message?.message?.length) {
        setError(message.message)
      }
    },
    messageSelector: (messages) => messages?.[0],
    resultSelector: (results) => results as OrderCustomerOrderRemarks,
  })

  const validateInputLength = useCallback(
    (input: string) => {
      if (order?.remarks?.maxLength && input.length > order.remarks.maxLength) {
        setError(
          i18next.t('formValidation.maxLength', { 0: order?.remarks?.maxLength })
        )

        return false
      }

      setError(null)

      return true
    },
    [order]
  )

  const submitInput = useCallback(
    (inputValue: string) => {
      if (!order?.remarks?.isEnabled || inputValue === order?.remarks?.value) return

      dispatch(createOrderMutation())
      post({
        orderNumber,
        remarkText: inputValue,
      })
    },
    [order, dispatch, post, orderNumber]
  )

  const eventHandlers = useMemo(
    () => ({
      onBlur: (e: FocusEvent<HTMLInputElement>) => {
        if (!validateInputLength(e.target.value)) return

        submitInput(e.target.value)
      },
      onKeyUp: (e: KeyboardEvent<HTMLInputElement>) => {
        if (!validateInputLength(e.currentTarget.value)) return
      },
      onPaste: (e: ClipboardEvent<HTMLInputElement>) => {
        validateInputLength(e.currentTarget.value)
      },
    }),
    [submitInput, validateInputLength]
  )

  useEffect(() => {
    if (error) {
      dispatch(createOrderMutation())
    } else {
      dispatch(markOrderMutationFinished())
    }
  }, [dispatch, error])

  if (!order?.remarks?.isEnabled) {
    return null
  }

  return (
    <FormControl error={!!error} sx={{ marginBottom: 4 }}>
      <FormInputLabel required={!order.isEditAllowed}>
        {order?.type === QUOTE
          ? i18next.t('shoppingLabels.quoteRemarks')
          : i18next.t('shoppingLabels.orderRemarks')}
      </FormInputLabel>

      <FormInputTextareaAsync
        data-t-id="order-remark"
        ref={inputRef}
        defaultValue={defaultValue}
        posting={posting}
        readOnly={!order?.isEditAllowed}
        {...eventHandlers}
      />

      <FormFieldText type="error">{error}</FormFieldText>
    </FormControl>
  )
}

export default SubmittableOrderField
