import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import i18next from 'i18next'
import { Box } from 'theme-ui'
import MultisupportUrgentTask from '../../organisms/MultisupportUrgentTask/MultisupportUrgentTask'
import { RootState } from '../../../reducers'
import { VehicleDetailResponse } from '../../../services/rest/ecommerce/dafVehicleDetail'
import {
  MILEAGE_INPUT_STATE_OPEN,
  RECALL_TYPE_PSFA,
  RECALL_TYPE_SR,
  SERVICEPLAN_CONTRACT_STATE_CANCELED,
  SERVICEPLAN_CONTRACT_STATE_CLOSED,
  SERVICEPLAN_CONTRACT_STATE_EXPIRED,
  SERVICEPLAN_CONTRACT_STATE_ON_HOLD,
} from '../../../constants/dafCompanyVehicleConstants'
import Container from '../../atoms/Container/Container'
import {
  toggleEditServicePlanPanel,
  toggleMileageSettlementInputPanel,
} from '../../../actions/dafCompanyVehicleActions'
import SidePanel from '../../organisms/SidePanel/SidePanel'
import DealerInformationOverview from '../../organisms/SidePanel/DealerInformation/DealerInformationOverview'
import {
  COMMERCE_SITE_CONTEXT,
  MULTISUPPORT_SITE_CONTEXT,
} from '../../../constants/environmentConstants'
import useSitecoreContext from '../../../hooks/useSitecoreContext'
import { VehicleReducerState } from '../../../reducers/dafCompanyVehicleReducer'
import { getQueryParam } from '../../../helpers/urlHelper'
import { IS_DEALER_ADMIN_QUERY_PARAM } from '../../../constants/urlConstants'

const VehicleDetailNotifications = () => {
  const { site } = useSitecoreContext()
  const dispatch = useDispatch()
  const [recallOptions, setRecallOptions] = useState<null | string[]>([])
  const { vehicleData, isFetching } = useSelector<RootState, VehicleReducerState>(
    (state: RootState) => state.dafCompanyVehicle
  )

  const { serviceDealer, isFetchingServiceDealer } = useSelector(
    (state: RootState) => state.dafCompanyVehicle
  )

  const [serviceDealerSidePanel, setServiceDealerSidePanel] = useState(false)
  const onClick = useCallback(() => {
    setServiceDealerSidePanel(!serviceDealerSidePanel)
  }, [serviceDealerSidePanel])

  const handleClickNotCoveredPlans = (vehicleDetail: VehicleDetailResponse) => {
    dispatch(
      toggleEditServicePlanPanel({
        isActive: true,
        chassisNumber:
          vehicleDetail?.vehicleDetail?.vehicleAttributes?.chassisNumber,
      })
    )
  }

  const [isDealerAdmin, setIsDealerAdmin] = useState(false)

  useEffect(() => {
    const isDealerAdmin = getQueryParam(IS_DEALER_ADMIN_QUERY_PARAM)
    if (isDealerAdmin === 'true') setIsDealerAdmin(true)
  }, [location])

  const getNotificationText = (vehicleDetail: VehicleDetailResponse | undefined) => {
    if (
      !vehicleDetail?.rmContract?.mostRecentContractForVehicle ||
      (site.name === COMMERCE_SITE_CONTEXT && !isDealerAdmin)
    )
      return null
    switch (vehicleDetail?.rmContract?.servicePlan?.status?.contractState) {
      case SERVICEPLAN_CONTRACT_STATE_EXPIRED:
        return i18next.t('urgentTasks.contractExpiredNotification')
      case SERVICEPLAN_CONTRACT_STATE_CANCELED:
        return i18next.t('urgentTasks.contractCanceledNotification')
      case SERVICEPLAN_CONTRACT_STATE_ON_HOLD:
        return i18next.t('urgentTasks.contractOnHoldNotification')
      case SERVICEPLAN_CONTRACT_STATE_CLOSED:
        return i18next.t('urgentTasks.contractClosedNotification')
      default:
        return null
    }
  }

  const isServicePlanExpiredOrCanceled =
    vehicleData?.rmContract?.servicePlan?.status?.contractState ===
      SERVICEPLAN_CONTRACT_STATE_CANCELED ||
    vehicleData?.rmContract?.servicePlan?.status?.contractState ===
      SERVICEPLAN_CONTRACT_STATE_EXPIRED

  const getMileageInputText = (vehicleDetail: VehicleDetailResponse | undefined) => {
    if (
      (site.name === MULTISUPPORT_SITE_CONTEXT || isDealerAdmin) &&
      vehicleDetail?.rmContract?.mostRecentContractForVehicle &&
      vehicleDetail?.rmContract?.finance?.mileageSettlementDetails
        ?.mileageSettlementInputState === MILEAGE_INPUT_STATE_OPEN
    ) {
      return i18next.t('urgentTasks.inputMileageNeeded')
    }
    return null
  }

  const getRecallOptions = (vehicleDetail: VehicleDetailResponse | undefined) => {
    const recallOptionsArray = []
    if (
      vehicleDetail?.rmContract?.mostRecentContractForVehicle ||
      !vehicleDetail?.rmContract
    ) {
      if (
        vehicleDetail?.servicePlanner?.service?.recalls.some(
          (recall) => recall.type === RECALL_TYPE_SR
        )
      ) {
        recallOptionsArray.push(RECALL_TYPE_SR)
        return recallOptionsArray
      }
      if (
        vehicleDetail?.servicePlanner?.service?.recalls.some(
          (recall) => recall.type === RECALL_TYPE_PSFA
        )
      ) {
        recallOptionsArray.push(RECALL_TYPE_PSFA)
        return recallOptionsArray
      }
    }

    return null
  }

  const getRecallText = (type: string) => {
    switch (type) {
      case RECALL_TYPE_SR:
        return i18next.t('urgentTasks.recalSr')
      case RECALL_TYPE_PSFA:
        return i18next.t('urgentTasks.recalPsfa')
      default:
        return null
    }
  }

  const getRecallVariant = (type: string) => {
    switch (type) {
      case RECALL_TYPE_SR:
        return 'urgentLarge'
      case RECALL_TYPE_PSFA:
        return 'warning'
      default:
        return 'ok'
    }
  }

  const onClickNotificationText = () => {
    if (
      (vehicleData?.rmContract?.mostRecentContractForVehicle &&
        vehicleData?.rmContract?.servicePlan?.status?.contractState ===
          SERVICEPLAN_CONTRACT_STATE_CANCELED) ||
      vehicleData?.rmContract?.servicePlan?.status?.contractState ===
        SERVICEPLAN_CONTRACT_STATE_EXPIRED
    ) {
      return i18next.t('urgentTasks.extendServicePlan')
    }
    return null
  }
  useEffect(() => {
    if (!vehicleData) return
    const newRecallOptions = getRecallOptions(vehicleData)
    setRecallOptions(newRecallOptions)
  }, [vehicleData])

  const notCoverednotificationText = getNotificationText(vehicleData)
  const mileageInputText = getMileageInputText(vehicleData)

  const hasCoveredNotifiction = !isFetching && notCoverednotificationText
  const hasRecallOptions = recallOptions && recallOptions.length > 0
  const hasMileageInput = !isFetching && mileageInputText

  return (
    <>
      {(hasCoveredNotifiction || hasRecallOptions || hasMileageInput) && (
        <Box>
          <Container>
            <Box sx={{ marginBlock: [40, 48] }}>
              {hasRecallOptions && (
                <SidePanel
                  active={serviceDealerSidePanel}
                  onClose={() => setServiceDealerSidePanel(false)}
                  title={i18next.t('urgentTasks.dealerInformation')}
                  position="right"
                >
                  {!isFetchingServiceDealer && (
                    <DealerInformationOverview
                      dealer={serviceDealer}
                      staticDealerFilters={[]}
                    />
                  )}
                </SidePanel>
              )}

              {getRecallOptions(vehicleData)?.map((recallOption) => (
                <MultisupportUrgentTask
                  key={recallOption}
                  dataTId={`warningtext-${recallOption}`}
                  variant={getRecallVariant(recallOption)}
                  value={getRecallText(recallOption)}
                  subValue={
                    site.name === MULTISUPPORT_SITE_CONTEXT
                      ? i18next.t('urgentTasks.seeMaintenanceTab')
                      : null
                  }
                  onClick={serviceDealer ? () => onClick() : undefined}
                  onClickText={
                    serviceDealer ? i18next.t('urgentTasks.contactDealerNow') : null
                  }
                />
              ))}

              {hasCoveredNotifiction && (
                <MultisupportUrgentTask
                  dataTId={`warningtext-${notCoverednotificationText}`}
                  variant="urgentLarge"
                  value={notCoverednotificationText}
                  onClick={
                    isServicePlanExpiredOrCanceled
                      ? () => handleClickNotCoveredPlans(vehicleData)
                      : undefined
                  }
                  onClickText={
                    isServicePlanExpiredOrCanceled ? onClickNotificationText() : null
                  }
                />
              )}

              {hasMileageInput && (
                <MultisupportUrgentTask
                  dataTId={`warningtext-${mileageInputText}`}
                  variant="urgentLarge"
                  value={mileageInputText}
                  onClick={() =>
                    dispatch(
                      toggleMileageSettlementInputPanel({
                        isActive: true,
                        chassisNumber:
                          vehicleData?.vehicleDetail.vehicleAttributes.chassisNumber,
                      })
                    )
                  }
                  onClickText={i18next.t('urgentTasks.mileageInput')}
                />
              )}
            </Box>
          </Container>
        </Box>
      )}
    </>
  )
}

export default VehicleDetailNotifications
