import React, { FC, useContext, useEffect, useState } from 'react'
import { Box, Button, Flex, Image, Text } from 'theme-ui'
import axios from 'axios'
import { useQuery } from '@tanstack/react-query'
import { useDispatch, useSelector } from 'react-redux'
import i18next from 'i18next'
import { WILDCARD_PAGE_CONTEXT_TYPE_VEHICLE } from '../../../providers/WildcardPageContextProvider'
import { VehicleContext } from '../../../types/sitecoreContextProps'
import useWildcardPageContext from '../../../hooks/useWildcardPageContext'
import { AxiosClientType } from '../../../providers/AxiosClientProvider'
import useAxiosClient from '../../../hooks/services/rest/core/useAxiosClient'
import { UserContext } from '../../../providers/UserProvider'
import fetchDafCompanyDetail, {
  DafVehicleDetailRequest,
} from '../../../services/rest/ecommerce/dafVehicleDetail'
import useSitecoreContext from '../../../hooks/useSitecoreContext'
import Spinner from '../../atoms/Spinner/Spinner'
import VehicleDetailLabelValueItem from '../VehicleDetail/VehicleDetailLabelValueItem'
import VehicleDetailTabs from '../VehicleDetail/VehicleDetailTabs'
import {
  pushToDafCompanyVehicle,
  pushToDafVehicleRmDocuments,
} from '../../../actions/dafCompanyVehicleActions'
import { COLORS } from '../../../constants/themeConstants'
import {
  getEngineType,
  getMileageValue,
  getVehicleConnectedValue,
  getVehicleLabel,
  validateAndGetDate,
} from '../../../helpers/vehicleHelper'
import { Datasource, LinkField } from '../../../types/layoutService'
import withData from '../../../enhancers/withData'
import ClickableWrapper from '../../atoms/ClickableWrapper/ClickableWrapper'
import EditServicePlanFormPanel from '../SidePanel/EditServicePlanFormPanel/EditServicePlanFormPanel'
import MileageSettlementInputFormPanel from '../SidePanel/MileageSettlementInputFormPanel/MileageSettlementInputFormPanel'
import { RootState } from '../../../reducers'
import {
  RequestMaintenanceAppointmentSidepanelDatasource,
  RequestNewMultisupportQuoteSidepanelDatasource,
  VehicleDescriptionProps,
} from '../../../types/VehicleDetailGenericComponentDataProps'
import fetchDafVehicleRmDocuments from '../../../services/rest/ecommerce/dafVehicleRmDocuments'
import RequestMaintenanceFormPanel from '../SidePanel/RequestMaintenanceFormPanel/RequestMaintenanceFormPanel'
import { ActiveStoreProviderContext } from '../../../providers/ActiveStoreProvider'
import { DafDefaultQueryParams } from '../../../types/userProps'
import MarkMaintenanceAsPerformedPanel from '../SidePanel/MarkMaintenanceAsPerformedPanel/MarkMaintenanceAsPerformedPanel'
import EndMaintenancePlanPanel from '../SidePanel/EndMaintenancePlanPanel/EndMaintenancePlanPanel'
import RequestMaintenanceAppointmentPanel from '../SidePanel/RequestMaintenanceAppointmentPanel/RequestMaintenanceAppointmentPanel'
import { getEditableTextFieldValue } from '../../../helpers/layoutServiceHelper'
import RequestQuotePanel from '../SidePanel/RequestQuotePanel/RequestQuotePanel'
import { ServicesProvider } from '../../../providers/ServicesProvider'
import { USER_ROLES_DEALER_CUSTOMERS_MAINTENANCE_AND_UPGRADE_INFORMATION } from '../../../constants/userConstants'
import { hasRole } from '../../../helpers/userHelper'
import { getQueryParam } from '../../../helpers/urlHelper'
import { IS_DEALER_ADMIN_QUERY_PARAM } from '../../../constants/urlConstants'

interface VehicleDetailHeaderDatasource {
  button?: LinkField
  descriptions: LinkField<VehicleDescriptionProps>
  requestNewMultisupportQuoteSidepanel: LinkField<RequestNewMultisupportQuoteSidepanelDatasource>
  requestMaintenanceAppointmentSidepanel: LinkField<RequestMaintenanceAppointmentSidepanelDatasource>
}

const VehicleDetailHeader: FC<Datasource<VehicleDetailHeaderDatasource>> = ({
  datasource: {
    button,
    descriptions,
    requestNewMultisupportQuoteSidepanel,
    requestMaintenanceAppointmentSidepanel,
  },
}) => {
  const vehicleContext = useWildcardPageContext<VehicleContext>(
    WILDCARD_PAGE_CONTEXT_TYPE_VEHICLE
  )
  const [isDealerAdmin, setIsDealerAdmin] = useState(false)
  const dispatch = useDispatch()
  const client = useAxiosClient(AxiosClientType.CommerceApi)
  const { user } = useContext(UserContext)
  const {
    languageContext: { cultureCode },
  } = useSitecoreContext()

  useEffect(() => {
    const isDealerAdmin = getQueryParam(IS_DEALER_ADMIN_QUERY_PARAM)
    if (isDealerAdmin === 'true') setIsDealerAdmin(true)
  }, [location])

  const { requestQuotePanelState, mileageSettlementInputAcceptedEstimation } =
    useSelector((state: RootState) => state.dafCompanyVehicle)

  const dafVehicleDetailRequest: DafVehicleDetailRequest = {
    chassisNumber: vehicleContext.chassisNumber,
    cultureCode,
    isDealerAdmin: isDealerAdmin,
  }

  const { actingCompanyId, actingSupplierId, isImpersonated } = useContext(
    ActiveStoreProviderContext
  )

  const dafVehicleDetailQueryParams: DafDefaultQueryParams = {
    customerCompanyId: actingCompanyId,
    supplierCompanyId: actingSupplierId,
    isImpersonated,
  }

  const getDafVehicleDetail = async () => {
    const { token } = axios.CancelToken.source()
    const response = await fetchDafCompanyDetail(
      dafVehicleDetailRequest,
      dafVehicleDetailQueryParams,
      client,
      token,
      user
    )
    return response.data
  }

  const {
    refetch: dafVehicleDetailRefetch,
    isLoading,
    data: dafVehicleDetailData,
  } = useQuery({
    queryKey: ['dafVehicleData'],
    queryFn: getDafVehicleDetail,
    enabled: !!actingSupplierId && !!actingCompanyId,
  })

  useEffect(() => {
    dispatch(
      pushToDafCompanyVehicle({
        data: dafVehicleDetailData?.result,
      })
    )
  }, [dispatch, dafVehicleDetailData])

  const getDafVehicleRmDocuments = async () => {
    const { token } = axios.CancelToken.source()
    const response = await fetchDafVehicleRmDocuments(
      dafVehicleDetailQueryParams,
      vehicleContext?.chassisNumber ?? '',
      client,
      token,
      user
    )
    return response.data
  }

  const { isFetching: fetchingDafVehicleRmDocuments, data: dafVehicleRmDocuments } =
    useQuery({
      queryKey: ['getDafVehicleRmDocuments'],
      queryFn: getDafVehicleRmDocuments,

      enabled:
        !!vehicleContext?.chassisNumber &&
        !!dafVehicleDetailData?.result?.rmContract &&
        !!actingCompanyId &&
        !!actingSupplierId,
    })

  useEffect(() => {
    dispatch(
      pushToDafVehicleRmDocuments({
        data: dafVehicleRmDocuments?.result,
        isFetching: fetchingDafVehicleRmDocuments,
      })
    )
  }, [dispatch, dafVehicleRmDocuments, fetchingDafVehicleRmDocuments])

  const mileageValue = getMileageValue(
    dafVehicleDetailData?.result?.mileage?.value,
    cultureCode
  )

  return (
    <>
      <Box sx={{ minHeight: '247px' }}>
        {!dafVehicleDetailData || isLoading ? (
          <Box
            sx={{
              paddingY: 5,
              width: '100%',
              height: '100%',
            }}
          >
            <Spinner />
            <Text
              sx={{
                display: 'block',
                textAlign: 'center',
                fontSize: 1,
                color: COLORS.MEDIUM_DARK,
                lineHeight: 3,
              }}
            >
              {i18next.t('myFleet.vehicleLoading')}
            </Text>
          </Box>
        ) : (
          <>
            {dafVehicleDetailData ? (
              <>
                <Text
                  as="h1"
                  variant="heading1"
                  sx={{
                    marginTop: hasRole(
                      user,
                      USER_ROLES_DEALER_CUSTOMERS_MAINTENANCE_AND_UPGRADE_INFORMATION
                    )
                      ? 5
                      : 0,
                    overflowWrap: 'break-word',
                  }}
                >
                  {getVehicleLabel(dafVehicleDetailData.result?.vehicleDetail)}
                </Text>

                <Flex sx={{ marginTop: 5, gap: 4 }}>
                  <Box
                    sx={{
                      flexShrink: 0,
                    }}
                  >
                    <Image
                      src={
                        dafVehicleDetailData.result?.vehicleDetail?.vehicleAttributes
                          ?.urlVehicleModelYearSeries
                      }
                      sx={{ width: '100px' }}
                    />
                  </Box>

                  <Box
                    sx={{
                      flexGrow: 1,
                    }}
                  >
                    <Box
                      as="dl"
                      sx={{
                        display: 'grid',
                        gridTemplateColumns:
                          'repeat(auto-fit, minmax(min(90px, 30%), 2fr) minmax(min(260px, 70%), 7fr))',
                        gap: 2,
                      }}
                    >
                      <VehicleDetailLabelValueItem
                        label={`${i18next.t('myFleet.modelTitle')}:`}
                        value={
                          dafVehicleDetailData.result?.vehicleDetail
                            ?.vehicleAttributes?.vehicleType
                        }
                        dataTId="vehicle-type"
                      />
                      <VehicleDetailLabelValueItem
                        label={`${i18next.t('myFleet.firstRegistrationTitleAbbr')}:`}
                        value={validateAndGetDate(
                          dafVehicleDetailData?.result?.vehicleDetail
                            ?.vehicleAttributes?.firstRegistrationDate
                        )}
                        dataTId="vehicle-registrationdate"
                      />
                      <VehicleDetailLabelValueItem
                        label={`${i18next.t('myFleet.connectedTitle')}:`}
                        value={getVehicleConnectedValue(dafVehicleDetailData)}
                        hideOnMobile
                        dataTId="vehicle-connected"
                      />
                      <VehicleDetailLabelValueItem
                        label={`${i18next.t('myFleet.vinTitle')}:`}
                        value={
                          dafVehicleDetailData?.result?.vehicleDetail
                            ?.vehicleAttributes?.vin
                        }
                        dataTId="vehicle-detail-vinnumber"
                      />
                      <VehicleDetailLabelValueItem
                        label={`${i18next.t('myFleet.engineTypeTitle')}:`}
                        value={getEngineType(dafVehicleDetailData)}
                        hideOnMobile
                        dataTId="vehicle-enginetype"
                      />
                      <VehicleDetailLabelValueItem
                        label={`${i18next.t('myFleet.mileageTitle')}:`}
                        value={mileageValue}
                        hideOnMobile
                        dataTId="vehicle-mileage"
                      />
                    </Box>
                  </Box>
                </Flex>

                <VehicleDetailTabs />
              </>
            ) : (
              <Flex
                sx={{
                  marginTop: 8,
                  justifyContent: 'center',
                  flexDirection: 'column',
                  textAlign: 'center',
                }}
              >
                <Text variant="heading2">
                  {i18next.t('myFleet.noVehicleResult')}
                </Text>
                <Text variant="bodyContent" color="gray.2" sx={{ marginTop: 3 }}>
                  {i18next.t('myFleet.noVehicleResultDescription')}
                </Text>
                <Flex sx={{ justifyContent: 'center', marginTop: 4 }}>
                  <ClickableWrapper href={button?.url} as="a">
                    <Button variant="primary" sx={{ cursor: 'pointer' }}>
                      {button?.text}
                    </Button>
                  </ClickableWrapper>
                </Flex>
              </Flex>
            )}
          </>
        )}
      </Box>

      <EditServicePlanFormPanel
        vehicle={dafVehicleDetailData?.result}
        messageOnSuccess={getEditableTextFieldValue(
          descriptions?.targetItem?.editServicePlanThankYouText
        )}
      />

      <MileageSettlementInputFormPanel
        vehicle={dafVehicleDetailData?.result}
        messageOnSuccess={getEditableTextFieldValue(
          descriptions?.targetItem?.inputMileageThankYou
        )}
        acceptedEstimation={mileageSettlementInputAcceptedEstimation}
        onFormSuccess={dafVehicleDetailRefetch}
      />

      <RequestMaintenanceFormPanel
        vehicle={dafVehicleDetailData?.result}
        onFormSuccess={dafVehicleDetailRefetch}
        noRequestForMaintenanceMessage={getEditableTextFieldValue(
          descriptions.targetItem?.noRequestForMaintenancePlanText
        )}
      />

      <MarkMaintenanceAsPerformedPanel onFormSuccess={dafVehicleDetailRefetch} />

      <EndMaintenancePlanPanel
        vehicle={dafVehicleDetailData?.result}
        onFormSuccess={dafVehicleDetailRefetch}
      />

      {requestMaintenanceAppointmentSidepanel?.targetItem && (
        <RequestMaintenanceAppointmentPanel
          datasource={requestMaintenanceAppointmentSidepanel.targetItem}
          vehicle={dafVehicleDetailData?.result}
        />
      )}

      <ServicesProvider
        chassisNumber={
          dafVehicleDetailData?.result?.vehicleDetail.vehicleAttributes.chassisNumber
        }
        enabled={requestQuotePanelState}
      >
        {requestNewMultisupportQuoteSidepanel?.targetItem && (
          <RequestQuotePanel
            datasource={requestNewMultisupportQuoteSidepanel.targetItem}
            vehicle={dafVehicleDetailData?.result}
          />
        )}
      </ServicesProvider>
    </>
  )
}

export default withData(VehicleDetailHeader)
