import React, { ReactNode } from 'react'
import { Box, Flex } from 'theme-ui'
import IconWrapper from '../IconWrapper/IconWrapper'
import { ICONS } from '../../../constants/iconConstants'

interface AccordeonProps {
  items: {
    title: string
    children: ReactNode
  }[]
}

const Accordeon = ({ items }: AccordeonProps) => {
  return (
    <Box
      as="ul"
      sx={{
        listStyle: 'none',
        padding: 0,
        borderBlockStart: '1px solid',
        borderColor: 'gray.1',
      }}
    >
      {items.map((item) => (
        <Box
          as="li"
          sx={{
            borderBlockEnd: '1px solid',
            borderColor: 'gray.1',
          }}
          key={item.title}
        >
          <Box
            as="details"
            sx={{
              '&[open] .icon': {
                rotate: '180deg',
              },
            }}
          >
            <Box
              as="summary"
              sx={{
                listStyle: 'none',
                '::-webkit-details-marker': {
                  display: 'none',
                },
                paddingBlock: 16,
              }}
            >
              <Flex sx={{ alignItems: 'center', justifyContent: 'space-between' }}>
                {item.title}

                <div className="icon">
                  <IconWrapper icon={ICONS.ARROW_DOWN} size={4} />
                </div>
              </Flex>
            </Box>
            <Box
              sx={{
                paddingBlockEnd: 2,
              }}
            >
              {item.children}
            </Box>
          </Box>
        </Box>
      ))}
    </Box>
  )
}

export default Accordeon
