import React, { FC, useContext, useMemo } from 'react'
import i18next from 'i18next'
import { StockLocation } from '../../../../hooks/services/graphql/usePartSalesInformationService'
import { ActiveStoreProviderContext } from '../../../../providers/ActiveStoreProvider'
import PaccarDistributionCenterStockInformationSidePanel from './PaccarDistributionCenterStockInformationSidePanel'
import {
  COMPANY_TYPE_CODE_HEADQUARTERS,
  COMPANY_TYPE_CODE_PACCAR_INC,
  COMPANY_TYPE_CODE_REGIONAL_OFFICE,
} from '../../../../constants/userConstants'
import DealerInformationSidePanel from '../DealerInformation/DealerInformationSidePanel'
import ConsignmentStockInformation from './ConsignmentStockInformation'
import { UserContext } from '../../../../providers/UserProvider'
import { STOCK_LOCATION_ID_CONSIGNMENT } from '../../../../constants/stockConstants'
import DealerInformationEmptySidePanel from '../DealerInformation/DealerInformationEmptySidePanel'

interface PartStockInformationSidePanelProps {
  partNumber?: string
  displayPartNumber?: string
  stockLocation?: StockLocation
  active?: boolean
  onClose: () => void
}

const INTERNAL_USER_COMPANY_TYPE_CODE_LIST = [
  COMPANY_TYPE_CODE_REGIONAL_OFFICE,
  COMPANY_TYPE_CODE_HEADQUARTERS,
  COMPANY_TYPE_CODE_PACCAR_INC,
]

const isInternalCompanyTypeCode = (companyTypeCode?: string): boolean =>
  !!companyTypeCode && INTERNAL_USER_COMPANY_TYPE_CODE_LIST.includes(companyTypeCode)

const isConsignmentStockLocation = (stockLocation?: StockLocation): boolean =>
  stockLocation?.locationId === STOCK_LOCATION_ID_CONSIGNMENT &&
  !!stockLocation?.consignmentStockInformation

const PartStockInformationSidePanel: FC<PartStockInformationSidePanelProps> = ({
  partNumber,
  stockLocation,
  active,
  onClose,
  ...props
}) => {
  const { userProfile } = useContext(UserContext)
  const { activeSupplier, activeSupplierInformation } = useContext(
    ActiveStoreProviderContext
  )

  const isConsignmentStock = isConsignmentStockLocation(stockLocation)

  const title = useMemo(() => {
    if (isConsignmentStock) {
      return i18next.t('stockLabels.consignmentStock')
    }

    return i18next.t('commonLabels.dealerInfo')
  }, [isConsignmentStock])

  const loggedInUserIsInternal = isInternalCompanyTypeCode(
    userProfile?.accountCompanyType
  )

  const activeSupplierIsInternal = isInternalCompanyTypeCode(
    activeSupplier?.companyTypeCode
  )

  if (loggedInUserIsInternal || activeSupplierIsInternal) {
    return (
      <PaccarDistributionCenterStockInformationSidePanel
        partNumber={partNumber || ''}
        active={active}
        onClose={onClose}
        dealerContactInformationVisible={!loggedInUserIsInternal}
        stockLocation={stockLocation}
        {...props}
      />
    )
  }

  if (activeSupplierInformation) {
    return (
      <DealerInformationSidePanel
        title={title}
        dealer={activeSupplierInformation}
        active={active}
        onClose={onClose}
        {...props}
      >
        {isConsignmentStock ? (
          <ConsignmentStockInformation
            partNumber={partNumber || ''}
            stockLocation={stockLocation}
            {...props}
          />
        ) : null}
      </DealerInformationSidePanel>
    )
  }

  return (
    <DealerInformationEmptySidePanel
      active={active}
      onClose={onClose}
      title={title}
    />
  )
}

export default PartStockInformationSidePanel
