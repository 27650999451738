import { useEffect, useMemo, useState } from 'react'
import useBreakpoints, { Breakpoints } from '../useBreakpoints'
import { SlidesPerPage } from './useSlider'

interface SliderBreakpointMap {
  slidesPerPage: number[]
  breakpoints: Breakpoints
}

const sliderBreakpointMap: SliderBreakpointMap[] = [
  {
    slidesPerPage: [1],
    breakpoints: ['xs'],
  },
  {
    slidesPerPage: [1, 2],
    breakpoints: ['xs', 's'],
  },
  {
    slidesPerPage: [1, 2, 3],
    breakpoints: ['xs', 's', 'm'],
  },
  {
    slidesPerPage: [1, 2, 2, 3, 4],
    breakpoints: ['xs', 's', 'm', 'l', 'xl'],
  },
  {
    slidesPerPage: [1, 2, 4, 4, 5],
    breakpoints: ['xs', 's', 'm', 'l', 'xl'],
  },
  {
    slidesPerPage: [1, 2, 4, 4, 6],
    breakpoints: ['xs', 's', 'm', 'l', 'xl'],
  },
]

const useSlidesPerPageResponsive = (initialSlidesPerPage: SlidesPerPage) => {
  const [slidesPerPage, setSlidesPerPage] = useState(initialSlidesPerPage)

  const breakpointMap = useMemo(
    () => sliderBreakpointMap?.[initialSlidesPerPage - 1] || {},
    []
  )

  const breakpoints = useBreakpoints(...(breakpointMap.breakpoints || []))

  useEffect(() => {
    // change items per page based on responsive settings
    if (breakpoints?.length) {
      const activeBreakpointIndex = breakpoints.lastIndexOf(true)

      setSlidesPerPage(
        breakpointMap.slidesPerPage[activeBreakpointIndex] as SlidesPerPage
      )
    }
  }, [breakpoints])

  return slidesPerPage
}

export default useSlidesPerPageResponsive
