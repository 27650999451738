import { PartCategory } from '../types/dafResponseProps'
import { URL_SPACE_DELIMITER } from '../constants/urlConstants'
import { isBrowser, runOnWindow } from './dom'

export const getUrlCompatibleQueryValue = (value: string): string =>
  encodeURIComponent(value.trim()).replace(/%20/g, URL_SPACE_DELIMITER)

export const getUrlQueryValue = (key: string, url?: string): string | undefined => {
  if (!isBrowser) return undefined

  const params = new URLSearchParams(url || runOnWindow((w) => w.location.search))

  return params.get(key) || undefined
}

export const addQueryParameterToUrl = (url: string, key: string, value: string) => {
  const urlParts = url.split('#')
  if (urlParts === undefined || urlParts.length === 0) return url

  if (!urlParts[0].toLowerCase().includes(key.toLowerCase())) {
    if (urlParts[0].includes('?')) {
      return joinOnFirstOccurrence(urlParts, key, value, '&', '#')
    }
    return joinOnFirstOccurrence(urlParts, key, value, '?', '#')
  }
  return urlParts.join('#')
}

const joinOnFirstOccurrence = (
  arr: string[],
  key: string,
  value: string,
  queryStringType: string,
  delimiter: string
) => {
  if (arr.length === 0) return ''
  if (arr.length === 1) return arr[0]
  return (
    arr[0] +
    `${queryStringType}${key}=${value}#${arr[1]}${delimiter}${arr
      .slice(2)
      .join('`${delimiter}`')}`
  )
}

export const getPartUrl = (partCategory: PartCategory, partNumber: string): string =>
  `${partCategory.mainCategoryId}/${partCategory.subCategoryId}/${partNumber}`

export const getReturnUrl = () => {
  const location = runOnWindow((w) => w.location)

  const pathname = location?.pathname // /en-gb/parts/1870/1933/1505241
  const pathnameSegments = pathname?.split('/') // ["", "en-gb", "parts", "1870", "1933", "1505241"]

  return pathnameSegments?.join('/') || ''
}

export const getUrlWithCurrentSearchParams = (url?: string, base?: string) => {
  const newUrl = new URL(
    url || runOnWindow((w) => w.location.href) || '/',
    base || runOnWindow((w) => w.location.origin)
  )
  const searchParams = new URLSearchParams(runOnWindow((w) => w.location.search))

  Array.from(searchParams.entries()).forEach(([key, value]) => {
    newUrl.searchParams.set(key, value)
  })

  return newUrl
}

export const getQueryParam = (paramKey: string) => {
  const queryParams = new URLSearchParams(runOnWindow((w) => w.location.search))
  return queryParams.get(paramKey)
}

export const removeQueryParam = (paramKey: string) => {
  const searchParams = new URLSearchParams(window.location.search)
  searchParams.delete(paramKey)
  if (window.history.replaceState) {
    const searchString =
      searchParams.toString().length > 0 ? `?${searchParams.toString()}` : ''
    const newUrl = `${window.location.protocol}//${window.location.host}${window.location.pathname}${searchString}${window.location.hash}`
    window.history.replaceState(null, '', newUrl)
  }
}

export const getUrlHash = (url?: string, base?: string) => {
  const urlObject = new URL(
    url || runOnWindow((w) => w.location.href) || '/',
    base || runOnWindow((w) => w.location.origin)
  )

  return urlObject.hash
}
