import React, { FC, ReactNode } from 'react'
import { Box, Text } from 'theme-ui'
import { getDividerSx } from '../../atoms/Divider/Divider'
import HotjarSuppress from '../../atoms/HotjarSuppress/HotjarSuppress'

interface SidePanelSectionProps {
  title?: string
  suppressTitle?: boolean
  children: ReactNode
}

const SidePanelSection: FC<SidePanelSectionProps> = ({
  children,
  title,
  suppressTitle = false,
}) => (
  <Box
    sx={{
      paddingBottom: 5,
      marginBottom: 5,
      ...getDividerSx('bottom'),
      ':last-child': { borderBottom: 'none' },
    }}
  >
    {title && (
      <HotjarSuppress condition={suppressTitle}>
        <Text
          as="h3"
          variant="heading3"
          color="gray.3"
          mb={2}
          data-t-id="side-panel-section-title"
        >
          {title}
        </Text>
      </HotjarSuppress>
    )}

    {children}
  </Box>
)

export default SidePanelSection
