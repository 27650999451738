import React from 'react'
import { Box, Text } from 'theme-ui'
import { RequestMaintenancePlanWizardDatasourceProps } from '../RequestMaintenancePlanWizard'
import { getTextFieldValue } from '../../../../helpers/layoutServiceHelper'
import { Vehicle } from '../../../../services/rest/ecommerce/dafCompanyVehicles'
import { COLORS } from '../../../../constants/themeConstants'
import VehicleGroupItem from './SelectVehicleGroupItem'
import NoResults from '../../../molecules/NoResults/NoResults'
import Button from '../../../atoms/Button/Button'
import { ICONS } from '../../../../constants/iconConstants'

interface SelectVehicleGroupProps {
  datasource: RequestMaintenancePlanWizardDatasourceProps
  vehicles: Record<string, Vehicle[]> | undefined
}

const SelectVehicleGroup = ({ datasource, vehicles }: SelectVehicleGroupProps) => {
  return (
    <Box>
      {vehicles && Object.keys(vehicles).length > 0 && (
        <>
          <Box>
            <Text
              variant="heading2"
              color={COLORS.BLACK}
              sx={{ fontSize: [4, 4, 4, 4] }}
            >
              {getTextFieldValue(datasource.stepOneTitle)}
            </Text>
          </Box>
          <Box>
            <Text variant="bodyCompact" color={COLORS.GRAY}>
              {getTextFieldValue(datasource.stepOneSubtitle)}
            </Text>
          </Box>
          <Box sx={{ marginBlockStart: 6 }}>
            {Object.entries(vehicles).map(([key, group]) => (
              <VehicleGroupItem
                key={key}
                group={group}
                noOfVehicleText={getTextFieldValue(datasource.noOfVehiclesText)}
                enterInformationText={getTextFieldValue(
                  datasource.enterInformationButtonText
                )}
              />
            ))}
          </Box>
        </>
      )}
      {vehicles && Object.keys(vehicles).length === 0 && (
        <Box sx={{ marginBlock: 5 }}>
          <NoResults
            description={getTextFieldValue(datasource.noResultsDescription)}
            title={getTextFieldValue(datasource.noResultsTitle)}
          >
            <Button
              as="a"
              href={datasource.backToMyFleetButton.url}
              variant="primary"
              icon={ICONS.ARROW_LEFT}
              iconPosition="start"
            >
              {datasource.backToMyFleetButton.text}
            </Button>
          </NoResults>
        </Box>
      )}
    </Box>
  )
}

export default SelectVehicleGroup
