import React, {
  Fragment,
  useCallback,
  useRef,
  forwardRef,
  useImperativeHandle,
} from 'react'
import { Form } from 'react-final-form'
import { Box } from 'theme-ui'
import i18next from 'i18next'
import { useRequestMaintenancePlan } from '../../../../providers/RequestMaintenancePlanProvider'
import { VehicleOption } from '../../../../services/rest/dmsc/dmscVehicleOptions'
import { DMSC_OPTION_LIST } from '../../../../constants/dmscConstants'
import { SelectField, TextField } from '../../../atoms/FormFields'
import { required } from '../../../../helpers/formFieldHelper'
import { FormApi, ValidationErrors } from 'final-form'
import { formatNumber } from '../../../../helpers/numberFormatHelper'
import useSitecoreContext from '../../../../hooks/useSitecoreContext'
import { InputMileageTextField } from '../../../atoms/FormFields/InputMileageTextField'

interface CustomFormData extends FormData {
  [key: string]: any
  estimatedAnnualDistance: number
}

interface UsageInformationFormProps {
  onFormErrorsChange: (hasErrors: boolean, vin: string) => void
  vin: string
}

export interface UsageInformationFormRef {
  getFormApi: () => FormApi<CustomFormData, Partial<CustomFormData>> | undefined
  getFormValues: () => Partial<CustomFormData> | undefined
}

const UsageInformationForm = forwardRef<
  UsageInformationFormRef,
  UsageInformationFormProps
>(({ onFormErrorsChange, vin }, ref) => {
  const { vehicleOptions } = useRequestMaintenancePlan()
  const {
    languageContext: { cultureCode },
  } = useSitecoreContext()

  // Use a ref to track if we've already sent error state to parent
  // This helps prevent unnecessary re-renders
  const errorStateRef = useRef<boolean | null>(null)

  // Store form API in a ref instead of state to prevent re-renders
  const formApiRef = useRef<FormApi<CustomFormData, Partial<CustomFormData>>>()

  // Expose methods to parent component via ref
  useImperativeHandle(
    ref,
    () => ({
      getFormApi: () => formApiRef.current,
      getFormValues: () => formApiRef.current?.getState().values,
    }),
    []
  )

  const i18nFormErrorFormatter = useCallback(
    (ruleName: string, args: string[]) =>
      i18next.t(`formValidation.${ruleName}`, { args }),
    []
  )

  const requiredValidation = useCallback(
    (value: string) => {
      return required(i18nFormErrorFormatter)(value)
    },
    [i18nFormErrorFormatter]
  )

  const vehicleOptionsSpecification = useCallback(() => {
    const filteredVehicleOptionsSpecification = vehicleOptions
      ? vehicleOptions.filter(
          (vehicleOption) =>
            vehicleOption.option.optionCategory === 'VehicleSpecification'
        )
      : []

    filteredVehicleOptionsSpecification.forEach((item) => {
      if (item.possibleValues && item.possibleValues.length > 0) {
        item.possibleValues.sort((a, b) => a.sortPosition - b.sortPosition)
      }
    })
    return filteredVehicleOptionsSpecification
  }, [vehicleOptions])

  const vehicleOptionsUsage = useCallback(() => {
    const filteredVehicleUseValues = vehicleOptions
      ? vehicleOptions.filter(
          (vehicleOption) => vehicleOption.option.optionCategory === 'VehicleUse'
        )
      : []

    filteredVehicleUseValues.forEach((item) => {
      if (item.possibleValues && item.possibleValues.length > 0) {
        item.possibleValues.sort((a, b) => a.sortPosition - b.sortPosition)
      }
    })

    return filteredVehicleUseValues
  }, [vehicleOptions])

  const renderListOrTextFieldOrNothing = useCallback(
    (option: VehicleOption) => {
      return (
        <>
          {option.hide ? null : option.option.optionType === DMSC_OPTION_LIST ? (
            <SelectField
              name={`s-${option.option.optionId}`}
              placeholder={i18next.t('commonFormLabels.selectAValue')}
              options={option.possibleValues.map((value) => ({
                value: value.id.toString(),
                text: value.description,
                selected: value.isDefault,
              }))}
              initialValue={
                option.preSelectValue
                  ? option.possibleValues
                      .find((value) => value.id === option.optionValue.valueId)
                      ?.id.toString()
                  : undefined
              }
              label={option.option.description}
              validate={requiredValidation}
              data-t-id={`panel-requestmaintenance-input-${option.option.optionId}`}
            />
          ) : (
            <TextField
              name={`s-${option.option.optionId}`}
              label={option.option.description}
              validate={requiredValidation}
              data-t-id={`panel-requestmaintenance-input-${option.option.optionId}`}
            />
          )}
        </>
      )
    },
    [requiredValidation]
  )

  const validateForm = useCallback(
    (values: CustomFormData) => {
      const vehicleOptionserrors: ValidationErrors = {}

      if (!values.estimatedAnnualDistance) {
        vehicleOptionserrors.estimatedAnnualDistance = i18next.t(
          'commonFormLabels.required'
        )
      }

      if (values.estimatedAnnualDistance && values.estimatedAnnualDistance < 1000) {
        vehicleOptionserrors.estimatedAnnualDistance = i18next.t(
          'errorMessages.estimatedAnnualDistanceAtLeast',
          { distance: formatNumber(1000, cultureCode) }
        )
      }

      if (vehicleOptions) {
        vehicleOptions.forEach((vehicleOption) => {
          const key = `s-${vehicleOption.option.optionId}`
          const value = (values as unknown as { [key: string]: string })[key]
          if (!vehicleOption.hide && !value) {
            vehicleOptionserrors[key] = i18next.t('commonFormLabels.required')
            vehicleOptionserrors.amendableVehicleOptions = i18next.t(
              'commonFormLabels.required'
            )
          }
        })
      }

      // Update error state outside the render cycle using ref
      const hasErrors = Object.keys(vehicleOptionserrors).length > 0

      // Only notify parent if error state has changed
      if (errorStateRef.current !== hasErrors) {
        errorStateRef.current = hasErrors

        // Use requestAnimationFrame to avoid state updates during render
        requestAnimationFrame(() => {
          onFormErrorsChange(hasErrors, vin)
        })
      }

      return vehicleOptionserrors
    },
    [vehicleOptions, cultureCode, onFormErrorsChange, vin]
  )

  return (
    <>
      <Form
        onSubmit={() => console.log('submit')}
        validate={validateForm}
        render={({ handleSubmit, form }) => {
          // Store form API in ref once - this won't trigger re-renders
          if (!formApiRef.current) {
            formApiRef.current = form
          }

          return (
            <form onSubmit={handleSubmit}>
              <Box
                sx={{
                  display: 'grid',
                  gridTemplateColumns: ['1fr', '1fr 1fr', '1fr 1fr 1fr'],
                  gap: '0 40px',
                }}
              >
                {vehicleOptions && (
                  <>
                    <InputMileageTextField
                      min={1000}
                      label={i18next.t(
                        'panels.requestMaintenanceEstimatedAnnualDistance'
                      )}
                      name="estimatedAnnualDistance"
                      data-t-id="panel-requestmaintenance-input-annualdistance"
                    />

                    {vehicleOptionsSpecification()?.map((option) => (
                      <Fragment key={option.option.optionId}>
                        {renderListOrTextFieldOrNothing(option)}
                      </Fragment>
                    ))}

                    {vehicleOptionsUsage()?.map((option) => (
                      <Fragment key={option.option.optionId}>
                        {renderListOrTextFieldOrNothing(option)}
                      </Fragment>
                    ))}
                  </>
                )}
              </Box>
            </form>
          )
        }}
      />
    </>
  )
})

export default UsageInformationForm
