import React, { useContext, useState } from 'react'
import parse from 'html-react-parser'
import SidePanel from '../../organisms/SidePanel/SidePanel'
import SidePanelBody from '../../organisms/SidePanel/SidePanelBody'
import SidePanelFooter from '../../organisms/SidePanel/SidePanelFooter'
import { RewardsContext } from '../../../providers/RewardsProvider'
import CardImage from '../Card/CardImage'
import AddToBasketQuantitySpinner from '../AddToBasket/AddToBasketQuantitySpinner/AddToBasketQuantitySpinner'
import i18next from 'i18next'
import { formatNumber } from '../../../helpers/numberFormatHelper'
import useSitecoreContext from '../../../hooks/useSitecoreContext'
import { Box, Flex, Text } from 'theme-ui'
import Button from '../../atoms/Button/Button'
import { ICONS } from '../../../constants/iconConstants'
import Accordeon from '../../atoms/Accordeon/Accordeon'
import { getFormattedPriceString } from '../../../helpers/priceHelper'
import RewardCardCreditVoucherCard from '../Card/RewardCard/RewardCardCreditVoucherCard'
import { PART_CARD_VARIANT_FULL } from '../Card/PartCard/PartCard'
import { LoyaltyContext } from '../../../providers/LoyaltyProvider'
import FormFieldText from '../../atoms/FormControls/FormFieldText'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { DafDefaultQueryParams } from '../../../types/userProps'
import { redeemReward } from '../../../services/rest/ecommerce/loyalty/rewards'
import { ActiveStoreProviderContext } from '../../../providers/ActiveStoreProvider'
import useAxiosClient from '../../../hooks/services/rest/core/useAxiosClient'
import { AxiosClientType } from '../../../providers/AxiosClientProvider'
import axios from 'axios'
import { UserContext } from '../../../providers/UserProvider'
import { addToast } from '../../../actions/toastActions'
import { useDispatch } from 'react-redux'
import RichText from '../../atoms/RichText/RichText'
import { RichTextField, TextField } from '../../../types/layoutService'
import {
  getEditableTextFieldValue,
  getTextFieldValue,
} from '../../../helpers/layoutServiceHelper'
import {
  REWARD_ERROR_REDEEM_NOT_ALLOWED,
  REWARD_ORDER_COSTS_MAXIMUM_EUR,
  REWARD_ORDER_COSTS_MINIMUM_EUR,
  REWARD_ORDER_MIN_AMOUNT_BENELUX_EUR,
  REWARD_ORDER_MIN_AMOUNT_NON_BENELUX_EUR,
  REWARD_TYPE_CREDIT_MERCHANDISE,
} from '../../../constants/loyaltyConstants'
import { QUERY_KEY_VOUCHERS } from '../../../constants/queryKeyConstants'

export interface RewardsSidepanelDatasource {
  howToRedeemPoints: TextField
  howToRedeemExplanation: RichTextField
  errorRedeemNotAllowed: RichTextField
  conditions: TextField
  conditionsCreditMerchandise: RichTextField
  conditionsProductMerchandise: RichTextField
  redeemPoints: TextField
  additionalPointsRequired: TextField
  redeemNow: TextField
  pointsPerProduct: TextField
  voucherCode: TextField
  voucherCodes: TextField
}

interface RewardsRedeemRewardSidepanelProps {
  datasource: RewardsSidepanelDatasource
}

const RewardsRedeemRewardSidepanel = ({
  datasource,
}: RewardsRedeemRewardSidepanelProps) => {
  const queryClient = useQueryClient()
  const dispatch = useDispatch()
  const { actingCompanyId, actingSupplierId, isImpersonated } = useContext(
    ActiveStoreProviderContext
  )
  const {
    languageContext: { cultureCode },
  } = useSitecoreContext()
  const client = useAxiosClient(AxiosClientType.CommerceApi)
  const { token } = axios.CancelToken.source()
  const { user } = useContext(UserContext)
  const { loyaltyData, refetchLoyaltyData } = useContext(LoyaltyContext)
  const {
    rewardSidePanelOpen,
    setRewardSidePanelOpen,
    rewardSidePanelReward,
    rewardSidePanelQuantity,
    setRewardSidePanelQuantity,
  } = useContext(RewardsContext)

  const [pointsRedeemed, setPointsRedeemed] = useState(false)
  const [redeemError, setRedeemError] = useState<string | null>(null)
  const [voucherCodes, setVoucherCodes] = useState<string[]>([])

  const onClose = () => {
    setRewardSidePanelOpen(false)
    setPointsRedeemed(false)
    setRedeemError(null)
  }

  const handleChangeQuantity = (quantity: number) => {
    setRewardSidePanelQuantity(quantity)
  }

  const hasNotEnoughPoints = () => {
    if (!rewardSidePanelReward || !loyaltyData?.customerLoyaltyPoints) {
      return false
    }

    return (
      rewardSidePanelReward.costPriceInPoints * rewardSidePanelQuantity >
      loyaltyData?.customerLoyaltyPoints?.currentPointsBalance
    )
  }

  const dafVehicleDetailQueryParams: DafDefaultQueryParams = {
    customerCompanyId: actingCompanyId,
    supplierCompanyId: actingSupplierId,
    isImpersonated,
  }

  const { isPending, mutate } = useMutation({
    mutationFn: () =>
      redeemReward(
        rewardSidePanelReward?.campaignId,
        rewardSidePanelReward?.rewardId,
        rewardSidePanelQuantity,
        dafVehicleDetailQueryParams,
        client,
        token,
        user
      ),
    onSuccess: (response) => {
      if (response?.data.messages.length) {
        setRedeemError(response.data.messages[0].message)
        return
      }

      setPointsRedeemed(true)
      refetchLoyaltyData()
      queryClient.invalidateQueries({ queryKey: [QUERY_KEY_VOUCHERS] })
      setVoucherCodes(response.data.result.voucherCodes)
    },
    onError: () => {
      setRedeemError('generic')
      refetchLoyaltyData()
    },
  })

  const handleClickRedeem = () => {
    mutate()
  }

  const writeClipboardText = async (text: string) => {
    await navigator.clipboard.writeText(text)

    dispatch(
      addToast({
        id: text,
        message: i18next.t('commonLabels.copiedToClipboard'),
      })
    )
  }

  return (
    <SidePanel
      position="right"
      title={getTextFieldValue(datasource.redeemPoints)}
      active={rewardSidePanelOpen}
      onClose={onClose}
    >
      <SidePanelBody>
        {!redeemError ? (
          <>
            {rewardSidePanelReward?.type === REWARD_TYPE_CREDIT_MERCHANDISE ? (
              <Box
                sx={{
                  marginBlockEnd: 24,
                }}
              >
                <RewardCardCreditVoucherCard
                  variant={PART_CARD_VARIANT_FULL}
                  creditValue={rewardSidePanelReward.creditValue}
                />
              </Box>
            ) : (
              <Box
                sx={{
                  marginBlockEnd: 24,
                  border: '1px solid',
                  borderColor: 'gray.1',
                  borderRadius: 'default',
                  padding: '3',
                  picture: {
                    maxBlockSize: '100px',
                  },
                }}
              >
                <CardImage
                  src={rewardSidePanelReward?.productImageUrl}
                  title={rewardSidePanelReward?.productName}
                />
              </Box>
            )}

            {rewardSidePanelReward?.costPriceInPoints &&
              loyaltyData?.customerLoyaltyPoints && (
                <Box
                  sx={{
                    marginBlockEnd: 24,
                  }}
                >
                  <Flex
                    sx={{
                      alignItems: 'center',
                      gap: 3,
                    }}
                  >
                    <AddToBasketQuantitySpinner
                      stepSize={1}
                      initialQuantity={rewardSidePanelQuantity}
                      maxQuantity={10}
                      onChange={handleChangeQuantity}
                      variant="small"
                      debounceInput={false}
                    />
                    <span>
                      <strong>
                        {formatNumber(
                          rewardSidePanelReward.costPriceInPoints *
                            rewardSidePanelQuantity,
                          cultureCode
                        )}
                      </strong>{' '}
                      {i18next.t('shoppingLabels.customerLoyalty.points')}
                    </span>
                  </Flex>

                  {hasNotEnoughPoints() && (
                    <FormFieldText type="error">
                      {getTextFieldValue(datasource.additionalPointsRequired)}{' '}
                      {formatNumber(
                        rewardSidePanelReward.costPriceInPoints *
                          rewardSidePanelQuantity -
                          loyaltyData?.customerLoyaltyPoints?.currentPointsBalance,
                        cultureCode
                      )}
                    </FormFieldText>
                  )}
                </Box>
              )}

            <Box
              sx={{
                marginBlockEnd: 24,
              }}
            >
              {rewardSidePanelReward?.type === REWARD_TYPE_CREDIT_MERCHANDISE ? (
                <>
                  <Text as="h4" variant="heading4">
                    {`${getFormattedPriceString(
                      cultureCode,
                      rewardSidePanelReward.creditValue?.currencyCode,
                      rewardSidePanelReward.creditValue?.value
                    )} ${i18next.t('shoppingLabels.customerLoyalty.credit')}`}
                  </Text>

                  <Text as="p" variant="bodySmall" color="gray.2">
                    {i18next.t(
                      'shoppingLabels.customerLoyalty.merchandiseStoreVoucher'
                    )}
                  </Text>
                </>
              ) : (
                <Text as="h4" variant="heading4">
                  {rewardSidePanelReward?.productName}
                </Text>
              )}

              {rewardSidePanelReward?.productDetailUrl && (
                <Button
                  as="a"
                  href={rewardSidePanelReward?.productDetailUrl}
                  target="_blank"
                  icon={ICONS.EXIT}
                  iconSize={4}
                  iconPosition="end"
                  variant="infinite"
                  color="primary"
                >
                  {i18next.t('shoppingLabels.customerLoyalty.productDetails')}
                </Button>
              )}
            </Box>

            <Box
              sx={{
                marginBlockEnd: 24,
              }}
            >
              {!pointsRedeemed ? (
                <>
                  <Text as="h4" variant="heading4">
                    {getTextFieldValue(datasource.pointsPerProduct)}
                  </Text>

                  <Text>
                    {formatNumber(
                      rewardSidePanelReward?.costPriceInPoints,
                      cultureCode
                    )}{' '}
                    {i18next.t('shoppingLabels.customerLoyalty.points')}
                  </Text>
                </>
              ) : (
                <>
                  <Text as="h4" variant="heading4">
                    {voucherCodes.length > 1
                      ? getTextFieldValue(datasource.voucherCodes)
                      : getTextFieldValue(datasource.voucherCode)}
                  </Text>
                  <Box
                    as="ul"
                    sx={{
                      padding: 0,
                      listStyle: 'none',
                    }}
                  >
                    {voucherCodes.map((voucherCode) => (
                      <Box
                        as="li"
                        sx={{
                          paddingBlock: 2,
                        }}
                        key={voucherCode}
                      >
                        <Button
                          onClick={() => writeClipboardText(voucherCode)}
                          color="primary"
                          variant="infinite"
                          iconPosition="end"
                          icon={ICONS.COPY}
                        >
                          {voucherCode}
                        </Button>
                      </Box>
                    ))}
                  </Box>
                </>
              )}
            </Box>

            <Box
              sx={{
                marginBlockStart: 64,
              }}
            >
              <Accordeon
                items={[
                  {
                    title: getTextFieldValue(datasource.howToRedeemPoints),
                    children: (
                      <RichText textField={datasource.howToRedeemExplanation} />
                    ),
                  },
                  {
                    title: getTextFieldValue(datasource.conditions),
                    children: (
                      <>
                        {parse(
                          i18next.t(
                            rewardSidePanelReward?.type ===
                              REWARD_TYPE_CREDIT_MERCHANDISE
                              ? datasource.conditionsCreditMerchandise?.jsonValue
                                  ?.value || ''
                              : datasource.conditionsProductMerchandise?.jsonValue
                                  ?.value || '',
                            {
                              minAmountBenelux: getFormattedPriceString(
                                cultureCode,
                                'EUR',
                                REWARD_ORDER_MIN_AMOUNT_BENELUX_EUR
                              ),
                              minAmountNonBenelux: getFormattedPriceString(
                                cultureCode,
                                'EUR',
                                REWARD_ORDER_MIN_AMOUNT_NON_BENELUX_EUR
                              ),
                              costsMinimum: getFormattedPriceString(
                                cultureCode,
                                'EUR',
                                REWARD_ORDER_COSTS_MINIMUM_EUR
                              ),
                              costsMaximum: getFormattedPriceString(
                                cultureCode,
                                'EUR',
                                REWARD_ORDER_COSTS_MAXIMUM_EUR
                              ),
                            }
                          )
                        )}
                      </>
                    ),
                  },
                ]}
              />
            </Box>
          </>
        ) : (
          <>
            {redeemError === REWARD_ERROR_REDEEM_NOT_ALLOWED ? (
              <Text>
                {parse(getEditableTextFieldValue(datasource.errorRedeemNotAllowed))}
              </Text>
            ) : (
              <Text>{i18next.t('errorMessages.pleaseTryAgainLater')}</Text>
            )}
          </>
        )}
      </SidePanelBody>
      <SidePanelFooter>
        <Flex
          sx={{
            flexDirection: 'column',
            gap: 16,
            inlineSize: '100%',
          }}
        >
          {!pointsRedeemed && !redeemError ? (
            <>
              <Button
                variant="primary"
                sx={{ width: '100%' }}
                icon={ICONS.REDEEM}
                disabled={hasNotEnoughPoints() || isPending}
                onClick={handleClickRedeem}
              >
                {getTextFieldValue(datasource.redeemNow)}
              </Button>
              <Button variant="outline" sx={{ width: '100%' }} onClick={onClose}>
                {i18next.t('commonFormLabels.cancel')}
              </Button>
            </>
          ) : (
            <Button variant="outline" sx={{ width: '100%' }} onClick={onClose}>
              {i18next.t('commonLabels.close')}
            </Button>
          )}
        </Flex>
      </SidePanelFooter>
    </SidePanel>
  )
}

export default RewardsRedeemRewardSidepanel
